import React, { Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { SET_COOKIE,IS_AUTHENTICATED } from "../Constants/AppConstants";
import requireCustAuth from "../Utils/requireCustAuth";
import Login from "../Components/Auth/Login";
import ServiceLogin from "../Components/Auth/ServiceLogin";
import PasswordReset from "../Components/Auth/PasswordReset";
import NewPasswordReset from "../Components/Auth/NewPasswordReset";
import moment from 'moment';

// Refer Start
import Home from "../Components/Refer/Home";
import Products from "../Components/Refer/Products";
import TopSellers from "../Components/Refer/TopSellers";
import BundleProduct from "../Components/Refer/BundleProduct";
import ProductDetails from "../Components/Refer/ProductDetails";
import CartPage from "../Components/Refer/CartPage";
import CheckOut from "../Components/Refer/CheckOut";
import CheckOutShipping from "../Components/Refer/checkoutShipping";
import CheckoutPayment from "../Components/Refer/checkoutPayment";
import OrderReceived from "../Components/Refer/OrderReceived";
import Athletes from "../Components/Pages/Athletes";
import GetInTouch from "../Components/Pages/GetInTouch";
import MyAccount from "../Components/Refer/Account/MyAccount";
import Page from "../Components/Pages/Page";
import CancellationProcess from "../Components/Pages/CancellationProcess";
import PageNotFound from "../Components/Pages/PageNotFound";
import Meals from "../Components/Refer/Meals";
import Plans from "../Components/Refer/Plans";
import Reorder from "../Components/Refer/Reorder";
import Downtime from '../Components/Pages/Downtime';
import ActivateMeal from "../Components/Pages/ActivateMeal";
import OrderConfirmed from "../Components/Refer/orderConfirmed";
import paypal_payment from "../Components/Refer/paypal_payment";
import GsxRedirect from "../Components/Refer/GsxRedirect";
import CreateCustomBundle from "../Components/Refer/CreateCustomBundle";
import TaggedProducts from "../Components/Refer/TaggedProducts";

class CommonRoutes extends Component {
    constructor(props) {
        super(props);

        const url = new URL(window.location.href);
        const af = url.searchParams.get("af");
        if (af) {
            SET_COOKIE("af", af);
            // const today = new Date();
            // let today_datetime = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate()+' '+today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
            const today_datetime = moment().format('YYYY-MM-DD HH:mm:ss')
            SET_COOKIE("af_created_date", today_datetime);
        }
    }

    render() {
        return (
            <Switch>
                <Route path="/login" component={Login} exact strict />
                <Route path="/serviceLogin" component={ServiceLogin} exact strict />
                <Route path="/password-reset" component={PasswordReset} exact strict />
                <Route path="/password-reset/:code" component={NewPasswordReset} exact strict />
                <Route path="/" component={Home} exact strict />
                <Route path="/products" component={Products} exact strict />
                <Route path="/best-sellers" component={TopSellers} exact strict />
                <Route path="/bundle-product" component={BundleProduct} exact strict />
                <Route path="/product/:slug" component={ProductDetails} exact strict />
                <Route path="/tagged-products/:tag_id" component={TaggedProducts} exact strict />
                <Route path="/cart" component={CartPage} exact strict />
                {/* <Route path="/checkout" component={requireCustAuth(CheckOut)} exact strict /> */}
                <Route path="/checkout" component={CheckOut} exact strict />
                <Route path="/checkout-shipping" component={CheckOutShipping} exact strict />
                <Route path="/checkout-payment" component={CheckoutPayment} exact strict />
                <Route path="/paypal-payment" component={paypal_payment} exact strict />
                <Route path="/paypal-payment-new/" component={paypal_payment} exact strict />
                <Route path="/order-confirmed/:order_id" component={OrderConfirmed} exact strict />
                <Route path="/meals" component={Plans} exact strict />
                <Route path="/meals/:id" component={Meals} exact strict />
                {/* <Route path="/order-received/:order_id" component={requireCustAuth(OrderReceived)} exact strict /> */}
                IS_AUTHENTICATED() ?<Route path="/order-received/:order_id" component={OrderReceived} exact strict />:<Route path="/order-received/:order_id/:token" component={OrderReceived} exact strict />
                <Route path="/athletes" component={Athletes} exact strict />
                <Route path="/contact" component={GetInTouch} exact strict />
                <Route path="/reorder/:order_id" component={Reorder} exact strict />
                <Route path='/cancellation-process' component={CancellationProcess} exact strict />
                <Route path="/page/:slug" component={Page} exact strict />
                <Route path="/my-account" component={MyAccount} exact strict />
                <Route path="/my-account/" component={MyAccount} exact strict />
                <Route path='/error' component={Downtime} exact strict />
                <Route path="/activate-meal" component={ActivateMeal} exact strict />
                <Route path="/gsx-redirect" component={GsxRedirect} exact strict />
                <Route path="/create-custom-bundle" component={CreateCustomBundle} exact strict />
                <Route component={PageNotFound} exact strict />
            </Switch>
        );
    }
}

export default withRouter(CommonRoutes);
