import React, { useEffect } from "react";
import {USER, IS_AUTHENTICATED, GET_STORAGE } from "../../Constants/AppConstants";

function FrontChatWidget() {
  useEffect(() => {
    
    function initFrontChat() {
      // const settings = JSON.parse(GET_STORAGE('settings'));
      if (IS_AUTHENTICATED()) {
        let User = JSON.parse(GET_STORAGE(USER));
        const userHash = (User.frontChat_UserHash_Token !== "") ? User.frontChat_UserHash_Token : ""; // Your Verification Secret
        window.FrontChat('init', {
          chatId: '7916c4acec9711522204b3f183a92396',
          useDefaultLauncher: true,
          email: User.email,
          name: User.first_name+' '+User.last_name,
          userHash: userHash
        });
      } else{
        window.FrontChat('init', {
            chatId: '7916c4acec9711522204b3f183a92396',
            useDefaultLauncher: true,
        });
      }
    }

    function loadFrontChatScript() {
      const script = document.createElement("script");
      script.id = "front-chat-script";
      script.src = "https://chat-assets.frontapp.com/v1/chat.bundle.js";
      script.async = false;
      script.onload = initFrontChat;
      document.body.appendChild(script);
    }
    window.onload=loadFrontChatScript();
  }, []);
  return <div className="front-chat-launcher"></div>;
}
export default FrontChatWidget;