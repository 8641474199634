import React, { PureComponent, Fragment, useEffect }  from "react";
import Parser from 'html-react-parser';
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import history from '../../history';
import { AJAX_REQUEST, CURRENCY_FORMAT, GET_STORAGE ,USER,IS_AUTHENTICATED, SET_STORAGE, SET_LOGIN_COOKIE,ORDER_RECEIVED, DESTROY_CART, createKlaviyoIdentify,DELETE_COOKIE} from "../../Constants/AppConstants";
import ReactImageFallback from "react-image-fallback";
import { Row, Col } from 'react-bootstrap';
import PrintIcon from '../../Assets/images/refer_images/print_icon.svg'
import InvoiceIcon from '../../Assets/images/refer_images/invoice_icon.svg'
import { setUserLogin } from '../../Store/actions/loginActions';
import { SET_CURRENT_USER } from '../../Store/actions/actionTypes';

class OrderReceived extends PureComponent {
    constructor(props) {
        super(props);
        // Check Tax
        let settings = '';
        if (GET_STORAGE('settings')) {
            settings = JSON.parse(GET_STORAGE('settings'));
        }

        this.state = {
            isAuthenticated: IS_AUTHENTICATED(),
            loading: true,
            error: '',
            orderReceived: [],
            taxStatus: settings ? settings.tax_status : 0,
        }
        document.title = "Order Recieved -Prestige Labs";
    }

    timeOut = (timedata) => {
        setTimeout(function () {
            history.push('/');
        }.bind(this), timedata);
    }

    componentDidMount() {
        document.querySelector("body").scrollIntoView();
        this.getOrderDetails();
        // this.YotpoReviews();
        // this.timeOut(5000);
    }

    getOrderDetails = () => {
        const c_user = JSON.parse(GET_STORAGE(USER));
        const data = {
            order_id: this.props.match.params.order_id,
            token: (this.props.match.params.token === null)? c_user.token: this.props.match.params.token ,
            is_order_received_page: 1,
        }
        AJAX_REQUEST("POST", "order/getReceived", data).then(results => {
            if (parseInt(results.response.code) === 1000) {
                
                this.props.setUserLogin(results.response.user_data) //set login data
                DESTROY_CART(); // Destroy cart information from localStorage
                // Set a new 'cart_cookie' and 'cartMethod_cookie' with an empty value to delete it
                document.cookie = "cart_cookie=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.prestigelabsaffs.com; secure; samesite=None;";
                document.cookie = "cartMethod_cookie=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.prestigelabsaffs.com; secure; samesite=None;";
                
                SET_STORAGE(ORDER_RECEIVED, 'yes'); //Set just receive a order by athore. do not delete that line
                this.setState({
                    loading: false,
                    orderReceived: results.response.data,
                });
                
                if (this.state.isAuthenticated === false) {
                    createKlaviyoIdentify();
                }
            } else {
                this.setState({
                    loading: false,
                    error: results.response.message,
                });
            }
        });
    }

    // YotpoReviews = () => {
    //     // Yotpo widget script
    //     const yotpoScript = document.createElement('script');
    //     yotpoScript.type = 'text/javascript';
    //     yotpoScript.async = true;
    //     yotpoScript.src = `//staticw2.yotpo.com/fbVo5bXhfhdTOlxWWl1ONhGnSs14G8Iz3ywgoDJS/widget.js`;
        
    //     // Append Yotpo script to the document
    //     const scriptTag = document.getElementsByTagName('script')[0];
    //     scriptTag.parentNode.insertBefore(yotpoScript, scriptTag);
    
    //     // Yotpo conversion tracking data
    //     const yotpoConversionData = {
    //       orderId: this.state.orderReceived.order_id,
    //       orderAmount: this.state.orderReceived.subtotal,
    //       orderCurrency: 'USD'
    //     };
    
    //     // Create script for Yotpo conversion tracking
    //     const conversionTrackingScript = document.createElement('script');
    //     conversionTrackingScript.text = `yotpoTrackConversionData = ${JSON.stringify(yotpoConversionData)};`;
    
    //     // Append conversion tracking script to the document
    //     document.body.appendChild(conversionTrackingScript);
    
    //     // Create noscript element for Yotpo conversion tracking
    //     const noscriptElement = document.createElement('noscript');
    //     noscriptElement.innerHTML = `<img src="//api.yotpo.com/conversion_tracking.gif?app_key=fbVo5bXhfhdTOlxWWl1ONhGnSs14G8Iz3ywgoDJS&order_id=${yotpoConversionData.orderId}&order_amount=${yotpoConversionData.orderAmount}&order_currency=${yotpoConversionData.orderCurrency}" width="1" height="1">`;
    
    //     // Append noscript element to the document
    //     document.body.appendChild(noscriptElement);
    // }

    render() {

        let orders = this.state.orderReceived;

        return (
            <Fragment>
                {
                    (this.state.loading) ?
                        <div className='loading container full_page_loader'></div>
                        :
                        <Fragment>
                            <div className="order_confirmed_page">
                                <div className="container">
                                    <div className="thankyou_page white_bg">
                                        <div className="thank_you_header cart_bor_btm">
                                            {
                                                (orders.length <= 0) ? '' :
                                                    orders.map(function (order, key) {
                                                        return (
                                                            <Fragment key={key}>
                                                                {
                                                                    (key !== 0) ? "" :
                                                                        <Fragment>
                                                                            <h2 className="font_24 text_black text-center let_spa_2 fw_el_bold m-0 line_height_38">{order.hasOwnProperty('success_message') ? Parser(order.success_message) : null} </h2>
                                                                            <p className="para font_16 text_black text-center mb_7 fw-ar_reg">We have received your order and are getting ready it to be shipped.</p>
                                                                        </Fragment>
                                                                }
                                                                <p className="para font_16 text_black text-center mb_58 fw-ar_reg">We will notify you when it’s on its way!</p>
                                                                <div className="d-flex align-items-center justify-content-between mb_18 order_no_date">
                                                                    <span className="d-block w-50 font_16 text_black let_spa_2 fw_ar_bold">Order No : #{order.hasOwnProperty("order_id") ? order.order_id : null}</span>
                                                                    <span className="d-block w-50 font_16 text_black text-right let_spa_2 fw_ar_bold">Order Date : {order.hasOwnProperty("created_at") ? order.created_at : null}</span>
                                                                </div>
                                                                <div className="cart_pro_blk">
                                                                {order.hasOwnProperty("is_custom_bundle") && order.is_custom_bundle == 1 ? 
                                                                    <span className="d-block w-50 font_16 text_black text-right let_spa_2 fw_ar_bold custom">
                                                                        Custom Bundle Products
                                                                    </span> 
                                                                    : null} 
                                                                    {
                                                                        (!order.hasOwnProperty("products")) ? null :
                                                                            order.products.map(function (product, key) {
                                                                                return (
                                                                                    <Fragment key={key}>
                                                                                        <div className="cart_pro_su d-flex align-items-center justify-content-between">
                                                                                            <div className="left_blk d-flex align-items-center">
                                                                                                <div className="cart_pro_img">
                                                                                                    <ReactImageFallback
                                                                                                        src={(product.product_thumbnail) ? product.product_thumbnail : ''}
                                                                                                        fallbackImage={require('../../Assets/images/preloader.gif')}
                                                                                                        initialImage={require('../../Assets/images/preloader.gif')}
                                                                                                        alt=''
                                                                                                        className="" />
                                                                                                </div>
                                                                                                <div className="cart_pro_det pl_9">
                                                                                                    <span className="d-block text_black left_spa_2 font_16 fw_el_bold line_height_25">{product.hasOwnProperty("name_with_variants") ? product.name_with_variants : null}</span>
                                                                                                    <span className="d-block items_text left_spa_2 font_12 fw_ar_reg">Month</span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="right_blk">
                                                                                                <span className="d-block font_14 fw_ar_bold let_spa_2 text_black text-right">{product.hasOwnProperty("total_price") ? CURRENCY_FORMAT(product.total_price) : null}</span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </Fragment>
                                                                                )
                                                                        }.bind(this))
                                                                    }
                                                                </div>


                                                            </Fragment>
                                                        )
                                                    }.bind(this))
                                            }
                                        </div>
                                        {
                                            (orders.length <= 0) ? '' :
                                                orders.map(function (order, key) {
                                                    return (
                                                        <Fragment key={key}>
                                                            <div className="thank_you_body cart_bor_btm">
                                                                <Row>
                                                                    <Col md={7}>
                                                                        <h4 className="font_16 text_black let_spa_2 fw_ar_bold mb_23">Payment & shipping details</h4>
                                                                        <ul className="payment_ship_ul">
                                                                            <li className="d-flex align-items-start mb_8">
                                                                                <span className="payment_me d-block font_14 text_black fw_ar_reg let_spa_2 position-relative">Payment Method  </span>
                                                                                <span className="credit_span d-block font_14 text_black fw_ar_reg let_spa_2">{order.hasOwnProperty("payment_method") ? order.payment_method : null} </span>
                                                                            </li>
                                                                            <li className="d-flex align-items-start mb_8">
                                                                                <span className="payment_me d-block font_14 text_black fw_ar_reg let_spa_2 position-relative">Delivered to  </span>
                                                                                <span className="credit_span d-block font_14 text_black fw_ar_reg let_spa_2">{order.shipping_address.hasOwnProperty("name") ? order.shipping_address.name : null}</span>
                                                                            </li>
                                                                            <li className="d-flex align-items-start ">
                                                                                <span className="payment_me d-block font_14 text_black fw_ar_reg let_spa_2 position-relative">Delivery Address </span>
                                                                                <span className="credit_span d-block font_14 text_black fw_ar_reg let_spa_2">{order.shipping_address.hasOwnProperty("street_address") ? order.shipping_address.street_address : null}
                                                                                    {order.shipping_address.hasOwnProperty("city") ? order.shipping_address.city : null}
                                                                                    {order.shipping_address.hasOwnProperty("state") ? order.shipping_address.state : null}
                                                                                    {order.shipping_address.hasOwnProperty("zip") ? order.shipping_address.zip : null}</span>
                                                                            </li>
                                                                        </ul>
                                                                    </Col>
                                                                    <Col md={5}>
                                                                        <ul className="pro_cart_det payemnt_total_pri">
                                                                            <li className="d-flex align-items-center flex-wrap mb_7">
                                                                                <span className="total_span text_black let_spa_2 d-block font_14 fw_ar_reg">Sub Total</span>
                                                                                <span className="price_span let_spa_2 text_black text-right d-block font_14 fw_ar_reg">{order.hasOwnProperty("subtotal") ? CURRENCY_FORMAT(order.subtotal) : null}</span>
                                                                            </li>
                                                                            {
                                                                                (order.discounts && order.discounts.length > 0) ?
                                                                                    order.discounts.map(function (discount, key) {
                                                                                        return (
                                                                                            <Fragment key={key}>
                                                                                                <li className="d-flex align-items-center flex-wrap mb_7">
                                                                                                    <span className="total_span text_black let_spa_2 d-block font_14 fw_ar_reg">{discount.hasOwnProperty("label") ? discount.label : null}</span>
                                                                                                    <span className="price_span let_spa_2 text_black text-right d-block font_14 fw_ar_reg">{discount.hasOwnProperty("amount") ? CURRENCY_FORMAT(discount.amount) : null}</span>
                                                                                                </li>
                                                                                            </Fragment>
                                                                                        )
                                                                                    }.bind(this))
                                                                                    : null
                                                                            }
                                                                            <li className="d-flex align-items-center flex-wrap mb_7">
                                                                                <span className="total_span text_black let_spa_2 d-block font_14 fw_ar_reg">Shipping</span>
                                                                                <span className="price_span let_spa_2 text_black text-right d-block font_14 fw_ar_reg">{order.hasOwnProperty("shipping_cost") ? CURRENCY_FORMAT(order.shipping_cost) : null} </span>
                                                                            </li>
                                                                            {
                                                                                (this.state.taxStatus == 1) ?
                                                                                    <Fragment>
                                                                                        <li className="d-flex align-items-center flex-wrap mb_7">
                                                                                            <span className="total_span text_black let_spa_2 d-block font_14 fw_ar_reg">Tax</span>
                                                                                            <span className="price_span let_spa_2 text_black text-right d-block font_14 fw_ar_reg">{order.hasOwnProperty("tax_amount") ? CURRENCY_FORMAT(order.tax_amount) : null}</span>
                                                                                        </li>
                                                                                    </Fragment>
                                                                                    : null
                                                                            }
                                                                            <li className="d-flex align-items-center flex-wrap">
                                                                                <span className="total_span text_black let_spa_2 d-block font_14 fw_ar_bold">Total</span>
                                                                                <span className="price_span let_spa_2 text_black text-right d-block font_14 fw_ar_bold">{order.hasOwnProperty("total") ? CURRENCY_FORMAT(order.total) : null}</span>
                                                                            </li>
                                                                        </ul>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </Fragment>
                                                    )
                                                }.bind(this))
                                        }
                                        <div className="thank_btn">
                                            <Row className='align-items-center'>
                                                <Col md={7}>
                                                    <a href="/" className="d-flex font_16 text_black text_underline fw_ar_reg">Continue Shopping</a>
                                                </Col>
                                                <Col md={5}>
                                                    <a href="/my-account" class="w-100 d-inline-block text-center shop_more take_quiz_btn font_16 text_white fw_ar_reg mobile_margin_top">My Orders</a>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                }
            </Fragment>
        );
    }
}

OrderReceived.propTypes = {
    auth: PropTypes.object.isRequired
}

function mapStateToProps(state) {
    return {
        auth: state.auth
    }
}
export default connect(mapStateToProps, { setUserLogin })(OrderReceived);
