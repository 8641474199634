import React, { Fragment, PureComponent } from 'react';
import { NavLink } from 'react-router-dom';
import $ from "jquery";
import { connect } from 'react-redux';
import { BUNDLE_COUNT, BUNDLE_SUB_TOTAL, CRYPTO_ENCRYPTION, IS_AUTHENTICATED, ORDER_RECEIVED, AJAX_PUBLIC_REQUEST, AJAX_REQUEST, AJAX_ACCOUNT_KIT_REQUEST, CURRENCY_FORMAT, CART_TOTAL_CURRENCY_FORMAT, COUNT_SUBSCRIPTION, SET_STORAGE, GET_STORAGE, REMOVE_STORAGE, CHECK_STORAGE, DESTROY_CART, ITEM_COUNT, MEAL_COUNT, NEXT_MONTH, NEXT_WEEK, MEAL_SUB_TOTAL, MEAL_TOTAL, CART_SUB_TOTAL, RECURRING_CART_SUB_TOTAL, COUPON_TOTAL, GET_COOKIE, DELETE_COOKIE, MEAL_MENU_PUBLIC, SITE, CanadaRestrictedProduct, klaviyoStartedCheckout, createKlaviyoIdentify, BUNDLE_CART_COUNT } from "../../Constants/AppConstants";
import PropTypes from "prop-types";
import classnames from 'classnames';
import history from "../../history";
import Parser from 'html-react-parser';
import { Row, Col } from 'react-bootstrap';
import LeftArrow from '../../Assets/images/refer_images/left_arrow.svg'
import BackArrow from '../../Assets/images/refer_images/back_arrow.svg'
import CheckoutLogo from '../../Assets/images/refer_images/checkout_page_logo.svg'
import CartSummaryIcon from '../../Assets/images/refer_images/cart_summary_icon.svg'

import AccountKit from 'react-facebook-account-kit';

import { checkoutRequest } from '../../Store/actions/checkoutActions';
import { setUserLogin } from '../../Store/actions/loginActions';

import checkoutValidate from '../../Validations/CheckoutValidate';
import colectEmailValidate from '../../Validations/collectEmailValidate';
import AlertWrapper from '../Common/AlertWrapper';
import AlertWrapperSuccess from '../Common/AlertWrapperSuccess';
import CartMonths from '../Common/CartMonths';
import CartYears from '../Common/CartYears';
import PreventCheckoutModal from './PreventCheckoutModal';
import CheckoutLogin from './partials/CheckoutLogin';
import AlertWrapperWarning from '../Common/AlertWrapperWarning';
import ReactImageFallback from "react-image-fallback";
// import ShippingSchedule from './ShippingSchedule';

class CheckOut extends PureComponent {
    constructor(props) {
        super(props);

        // Check Tax
        let settings = '';
        if (GET_STORAGE('settings')) {
            settings = JSON.parse(GET_STORAGE('settings'));
        }

        this.state = {
            loading: true,
            error: '',
            stockoutErrorMessage: '',
            terms_of_use: settings ? (settings.internal_pages ? settings.internal_pages.terms_of_use : '/') : "/",
            privacy_policy: settings ? (settings.internal_pages ? settings.internal_pages.privacy_policy : '/') : "/",

            // How did you hear about us?
            isEnableHowYouKnow: (settings.is_enable_how_you_know == 'yes') ? "yes" : "no",
            isKnowOthers: false,
            howYouKnowOptions: [],
            how_you_know: "",
            how_you_know_others: "",
            conditional_item: [],

            // Facebook mobile verification
            phone_verification_on_checkout: settings ? settings.phone_verification_on_checkout : '',
            facebook_app_id: settings ? settings.facebook_app_id : '',
            account_kit_app_secret: settings ? settings.account_kit_app_secret : '',
            account_kit_api_version: settings ? settings.account_kit_api_version : '',

            cartShippingCost: 0,
            recurringCartShippingCost: 0,
            cartTotal: 0,
            mealShippingCostStatus: true,

            taxStatus: settings ? settings.tax_status : 0,
            tax_amount: 0,
            tax_info: '',
            subscription_tax_amount: 0,
            subscription_tax_info: '',
            meal_tax_amount: 0,
            meal_tax_info: '',
            subscription_meal_tax_amount: 0,
            subscription_meal_tax_info: '',
            affiliate_code: GET_COOKIE('af'),
            affiliate_code_created_date: GET_COOKIE('af_created_date'),

            items: [],
            meals: [],
            coupons: [],
            shippingMethods: [],
            countryList: [],
            billingStateList: [],
            shippingStateList: [],
            freeShipping: 0,
            taxFree: 0,
            show_coupon: false,
            coupon_code_button_loading: false,
            coupon_code: "",
            success_alert_wrapper_show: false,
            warning_wrapper_show: false,
            place_order_loading: false,

            // Billing Details
            billing_first_name: '',
            billing_last_name: '',
            billing_company: '',
            billing_country: '',
            billing_address_1: '',
            billing_address_2: '',
            billing_city: '',
            billing_state: '',
            billing_postcode: '',
            billing_phone: '',
            billing_email: '',

            // Address verify
            addressVerifyCode: false,
            continue_without_verify: 0,
            agree_to_receive_text: 'yes',
            send_promotion_update: 'yes',

            // Different Shipping Address
            shippingAreaVisible: false,
            ship_to_different_address: 'no',

            shipping_first_name: '',
            shipping_last_name: '',
            shipping_company: '',
            shipping_country: '',
            shipping_address_1: '',
            shipping_address_2: '',
            shipping_city: '',
            shipping_state: '',
            shipping_postcode: '',

            order_note: '',

            // Payment Method
            payment_method: (settings.payment_method) ? settings.payment_method : '',
            name_on_card: '',
            card_number: '',
            expire_month: '',
            expire_year: '',
            cvv: '',
            agreeTermsPolicy: false,

            // Validation
            errors: {},
            isValid: false,
            isLoading: false,
            isFormValid: true,
            server_message: '',

            isFormDisabled: IS_AUTHENTICATED() ? false : true,
            isAuthenticated: IS_AUTHENTICATED(),
            user_email: '',

            //warning state
            productWarnings: [],
            warningProductCartKeys: [],
            bundle: [],
            is_california_warning: 'no',
            subscription: GET_STORAGE("is_subscription"),
            subscription_detail: JSON.parse(GET_STORAGE("CustomSubscriptionDetail")),
            selectedWarning: []
        }
        document.title = "CheckOut-Prestige Labs";

    }

    changeHandler = (e) => {
        const { name, value } = e.target;
        
        // let cart_items = (ITEM_COUNT() == 0) ? JSON.parse(GET_STORAGE("BundleCart")) : JSON.parse(GET_STORAGE("cart"));
        // const hasShipmonkWarehouse = cart_items.some(item => item.warehouse == 'shipmonk');

        if(name === 'billing_company' && value.length > 30){
            this.setState({
                errors: {
                    ...this.state.errors,
                    billing_company: 'Company name should not exceed 30 characters.',
                },
            });
        }else if (name === 'billing_company' && value.length > 50) {
            this.setState({
                errors: {
                    ...this.state.errors,
                    billing_company: 'Company name should not exceed 50 characters.',
                },
            });
        } else if(name === 'billing_company'){
            this.setState({
                errors: {
                    ...this.state.errors,
                    billing_company: '',
                },
            });
        }

        if (e.target.name === 'name_on_card') {
            const name_on_card = e.target.value;
            this.setState({
                [e.target.name]: name_on_card.toUpperCase()
            })
        } else if (e.target.name === 'agree_to_receive_text') {
            if (document.getElementById("agree_to_receive_text").checked) {
                this.setState({ agree_to_receive_text: "yes" });
            } else {
                this.setState({ agree_to_receive_text: "no" });
            }
        } else {
            this.setState({
                [e.target.name]: e.target.value
            })
        }

    }

    checkOutLoginHandler = (data) => {
        this.setState({
            success_alert_wrapper_show: false,
            warning_wrapper_show: false
        })

        if (data.type === 'success') {
            this.setState({
                isAuthenticated: true,
                isFormDisabled: false,
                isLoading: false,
                isFormValid: true,
                server_message: ''
            });

            this.getUserAdditionalData()
        }
        else {
            this.setState({
                server_message: data.message,
                isLoading: false,
                isFormValid: false
            });
        }
    }

    checkoutEmailCheckHandler = (data) => {
        this.setState({
            success_alert_wrapper_show: false,
            warning_wrapper_show: false
        })

        if (data.action && data.action === 'change-email') {
            this.setState({
                user_email: '',
                billing_email: '',
                isFormDisabled: true,
                isFormValid: true
            });
        }
        else if (data.action && data.action === 'user-found') {
            this.setState({
                isFormDisabled: true,
                isFormValid: true,
                success_alert_wrapper_show: true,
                server_message: data.message
            });
        }
        else if (data.type === 'success' && (data.action && data.action === 'no-user-found')) {
            this.setState({
                user_email: data.user_email,
                billing_email: data.user_email,
                isFormDisabled: false,
                isFormValid: true,
                server_message: data.message
            });
        }
        else {
            this.setState({
                server_message: data.message,
                isFormValid: false,
                isFormDisabled: true
            });
        }
    }

    // How did you hear about us?
    howKnowChangeHandler = (e) => {
        if (e.target.value == 'others') {
            this.setState({
                isKnowOthers: true,
                [e.target.name]: e.target.value
            })
        } else {
            this.setState({
                isKnowOthers: false,
                [e.target.name]: e.target.value,
                how_you_know_others: ""
            })
        }
    }

    changeHandlerWithCallTax = (e) => {
        this.setState({ [e.target.name]: e.target.value });
        this.getTax(this.state.ship_to_different_address);

        //console.log('target name: ', e.target.name);
        //console.log('settings: ', this.state.warnings);

        if (this.state.ship_to_different_address === 'checked') {
            if (e.target.name === 'shipping_state') {
                this.warningModalHandel(this.state.shipping_country, e.target.value);
            }
        } else if (e.target.name === 'billing_state') {
            this.warningModalHandel(this.state.billing_country, e.target.value);
        }
    }

    warningModalCancelHandel = (e) => {
        //console.log('warningModalCancelHandel click: ', this.state.warningProductCartKeys);
        const productKeys = (this.state.warningProductCartKeys) ? this.state.warningProductCartKeys : [];
        if (productKeys.length > 0) {
            let cartItems = (ITEM_COUNT() == 0) ? JSON.parse(GET_STORAGE("BundleCart")) : JSON.parse(GET_STORAGE("cart"));

            let newCart = [];
            if (cartItems.length > 0) {
                cartItems.map(function (item, productKey) {
                    if (productKeys.includes(productKey)) {
                        //console.log('cart item found: ', item);
                    } else {
                        newCart.push(item);
                    }
                })
            }

            //console.log('warningModalCancelHandel newCart: ', newCart);


            SET_STORAGE("cart", JSON.stringify(newCart));

            setTimeout(() => {
                $('#warningModal').modal('hide');
                //redirect to card page
                history.push("/cart");
            }, 100);

        }
    }


    warningModalHandel = (country, state) => {
        AJAX_PUBLIC_REQUEST("POST", "user/getWarnings", {}).then(results => {
            // console.log('cartItems results: ', results);

            if (parseInt(results.response.code) === 1000) {
                const warnings = (results.response.data) ? results.response.data : [];
                let selectedWarning = [];
                if (warnings.length > 0) {
                    let warningContents = '';
                    let warningProductKeys = [];
                    const cartItems = (ITEM_COUNT() == 0) ? JSON.parse(GET_STORAGE("BundleCart")) : JSON.parse(GET_STORAGE("cart"));
                    if (cartItems.length > 0) {
                        cartItems.map(function (item, productKey) {
                            //console.log('cartItems item: ', item);
                            let productWarnings = '';
                            let cartProductId = item.cart_product_id;
                            let cartVariationId = item.cart_variation_id;
                            warnings.map(function (warning, key) {
                                //console.log('warning: ', warning);
                                //console.log('warning locations: ', warning.locations);
                                if (warning.status === 'active') {
                                    //check location
                                    if (warning.targeted_location === 'yes') {
                                        const locations = warning.locations;
                                        const locationStates = locations[country] ? locations[country] : [];
                                        if (locationStates.length > 0) {
                                            if (locationStates.includes(state)) {
                                                if (warning.targeted_location_type === 'show') {
                                                    //console.log('state found: ', state);
                                                    //check product
                                                    if (warning.targeted_product_type === 'all') {
                                                        if (productWarnings.length === 0) {
                                                            warningProductKeys.push(productKey);
                                                            productWarnings += '<div class="d-flex align-items-start justify-content-center warning-block-border"><div class="d-flex flex-column align-items-center justify-content-center" style="width: 120px;padding-top: 12px;border: 1px solid #ddd"><img src="' + item.cart_image + '" width="90%" /> <p style="padding: 5px;font-size: 11px;line-height: 1.2;text-align: center;margin-bottom: 0!important;">' + item.cart_product_name + ' - ' + item.cart_variation_name + '</p></div><div class="d-flex flex-column w-100">';
                                                            selectedWarning.push({ warning });
                                                        }
                                                        productWarnings += '<div class="py-3 px-3 d-flex ml-2 border warning-message-text" style="background-color: ' + warning.design.background + '"><div class="mr-3 d-flex align-items-center"><img src="' + warning.design.icon + '" width="50px"></div><div class="d-flex align-items-center"><div class="text-left warning-messages-block">' + warning.messages + '</div></div></div>';
                                                    } else if (warning.targeted_product_type === 'product') {//variation wise check
                                                        const productsIds = warning.products_ids;
                                                        if (productsIds.length > 0) {
                                                            if (productsIds.includes(parseInt(cartProductId))) {
                                                                if (productWarnings.length === 0) {
                                                                    warningProductKeys.push(productKey);
                                                                    productWarnings += '<div class="d-flex align-items-start justify-content-center warning-block-border"><div class="d-flex flex-column align-items-center justify-content-center" style="width: 120px;padding-top: 12px;border: 1px solid #ddd"><img src="' + item.cart_image + '" width="90%" /> <p style="padding: 5px;font-size: 11px;line-height: 1.2;text-align: center;margin-bottom: 0!important;">' + item.cart_product_name + ' - ' + item.cart_variation_name + '</p></div><div class="d-flex flex-column w-100">';
                                                                }
                                                                productWarnings += '<div class="py-3 px-3 d-flex ml-2 border warning-message-text" style="background-color: ' + warning.design.background + '"><div class="mr-3 d-flex align-items-center"><img src="' + warning.design.icon + '" width="50px"></div><div class="d-flex align-items-center"><div class="text-left warning-messages-block">' + warning.messages + '</div></div></div>';
                                                                selectedWarning.push({ warning });
                                                            }
                                                        }
                                                    } else if (warning.targeted_product_type === 'variation') {//variation wise check
                                                        const productsIds = warning.products_ids;
                                                        if (productsIds.length > 0) {
                                                            if (productsIds.includes(parseInt(cartVariationId))) {
                                                                if (productWarnings.length === 0) {
                                                                    warningProductKeys.push(productKey);
                                                                    productWarnings += '<div class="d-flex align-items-start justify-content-center warning-block-border"><div class="d-flex flex-column align-items-center justify-content-center" style="width: 120px;padding-top: 12px;border: 1px solid #ddd"><img src="' + item.cart_image + '" width="90%" /> <p style="padding: 5px;font-size: 11px;line-height: 1.2;text-align: center;margin-bottom: 0!important;">' + item.cart_product_name + ' - ' + item.cart_variation_name + '</p></div><div class="d-flex flex-column w-100">';
                                                                }
                                                                productWarnings += '<div class="py-3 px-3 d-flex ml-2 border warning-message-text" style="background-color: ' + warning.design.background + '"><div class="mr-3 d-flex align-items-center"><img src="' + warning.design.icon + '" width="50px"></div><div class="d-flex align-items-center"><div class="text-left warning-messages-block">' + warning.messages + '</div></div></div>';
                                                                selectedWarning.push({ warning });
                                                            }
                                                        }
                                                    }
                                                }
                                            } else { //not include
                                                if (warning.targeted_location_type === 'dont_show') {
                                                    //check product
                                                    if (warning.targeted_product_type === 'all') {
                                                        if (productWarnings.length === 0) {
                                                            warningProductKeys.push(productKey);
                                                            productWarnings += '<div class="d-flex align-items-center justify-content-center"><div class="d-flex flex-column align-items-start justify-content-center" style="width: 120px;padding-top: 12px;border: 1px solid #ddd"><img src="' + item.cart_image + '" width="90%" /> <p style="padding: 5px;font-size: 11px;line-height: 1.2;text-align: center">' + item.cart_product_name + ' - ' + item.cart_variation_name + '</p></div><div class="d-flex flex-column w-100">';
                                                        }
                                                        productWarnings += '<div class="py-3 px-3 d-flex ml-2 border warning-message-text" style="background-color: ' + warning.design.background + '"><div class="mr-3 d-flex align-items-center"><img src="' + warning.design.icon + '" width="50px"></div><div class="d-flex align-items-center"><div class="text-left warning-messages-block">' + warning.messages + '</div></div></div>';
                                                        selectedWarning.push({ warning });
                                                    } else if (warning.targeted_product_type === 'product') {//variation wise check
                                                        const productsIds = warning.products_ids;
                                                        if (productsIds.length > 0) {
                                                            if (productsIds.includes(parseInt(cartProductId))) {
                                                                if (productWarnings.length === 0) {
                                                                    warningProductKeys.push(productKey);
                                                                    productWarnings += '<div class="d-flex align-items-start justify-content-center warning-block-border"><div class="d-flex flex-column align-items-center justify-content-center" style="width: 120px;padding-top: 12px;border: 1px solid #ddd"><img src="' + item.cart_image + '" width="90%" /> <p style="padding: 5px;font-size: 11px;line-height: 1.2;text-align: center;margin-bottom: 0!important;">' + item.cart_product_name + ' - ' + item.cart_variation_name + '</p></div><div class="d-flex flex-column w-100">';
                                                                }
                                                                productWarnings += '<div class="py-3 px-3 d-flex ml-2 border warning-message-text" style="background-color: ' + warning.design.background + '"><div class="mr-3 d-flex align-items-center"><img src="' + warning.design.icon + '" width="50px"></div><div class="d-flex align-items-center"><div class="text-left warning-messages-block">' + warning.messages + '</div></div></div>';
                                                                selectedWarning.push({ warning });
                                                            }
                                                        }
                                                    } else if (warning.targeted_product_type === 'variation') {//variation wise check
                                                        const productsIds = warning.products_ids;
                                                        if (productsIds.length > 0) {
                                                            if (productsIds.includes(parseInt(cartVariationId))) {
                                                                if (productWarnings.length === 0) {
                                                                    warningProductKeys.push(productKey);
                                                                    productWarnings += '<div class="d-flex align-items-start justify-content-center warning-block-border"><div class="d-flex flex-column align-items-center justify-content-center" style="width: 120px;padding-top: 12px;border: 1px solid #ddd"><img src="' + item.cart_image + '" width="90%" /> <p style="padding: 5px;font-size: 11px;line-height: 1.2;text-align: center;margin-bottom: 0!important;">' + item.cart_product_name + ' - ' + item.cart_variation_name + '</p></div><div class="d-flex flex-column w-100">';
                                                                }
                                                                productWarnings += '<div class="py-3 px-3 d-flex ml-2 border warning-message-text" style="background-color: ' + warning.design.background + '"><div class="mr-3 d-flex align-items-center"><img src="' + warning.design.icon + '" width="50px"></div><div class="d-flex align-items-center"><div class="text-left warning-messages-block">' + warning.messages + '</div></div></div>';
                                                                selectedWarning.push({ warning });
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        //console.log('billing_country: ', country);
                                        //console.log('locations: ', locations[country]);
                                    } else {
                                        //check product
                                        if (warning.targeted_product_type === 'all') {
                                            if (productWarnings.length === 0) {
                                                warningProductKeys.push(productKey);
                                                productWarnings += '<div class="d-flex align-items-start justify-content-center warning-block-border"><div class="d-flex flex-column align-items-center justify-content-center" style="width: 120px;padding-top: 12px;border: 1px solid #ddd"><img src="' + item.cart_image + '" width="90%" /> <p style="padding: 5px;font-size: 11px;line-height: 1.2;text-align: center;margin-bottom: 0!important;">' + item.cart_product_name + ' - ' + item.cart_variation_name + '</p></div><div class="d-flex flex-column w-100">';
                                            }
                                            productWarnings += '<div class="py-3 px-3 d-flex ml-2 border warning-message-text" style="background-color: ' + warning.design.background + '"><div class="mr-3 d-flex align-items-center"><img src="' + warning.design.icon + '" width="50px"></div><div class="d-flex align-items-center"><div class="text-left warning-messages-block">' + warning.messages + '</div></div></div>';
                                            selectedWarning.push({ warning });
                                        } else if (warning.targeted_product_type === 'product') {//variation wise check
                                            const productsIds = warning.products_ids;
                                            if (productsIds.length > 0) {
                                                if (productsIds.includes(parseInt(cartProductId))) {
                                                    if (productWarnings.length === 0) {
                                                        warningProductKeys.push(productKey);
                                                        productWarnings += '<div class="d-flex align-items-start justify-content-center warning-block-border"><div class="d-flex flex-column align-items-center justify-content-center" style="width: 120px;padding-top: 12px;border: 1px solid #ddd"><img src="' + item.cart_image + '" width="90%" /> <p style="padding: 5px;font-size: 11px;line-height: 1.2;text-align: center;margin-bottom: 0!important;">' + item.cart_product_name + ' - ' + item.cart_variation_name + '</p></div><div class="d-flex flex-column w-100">';
                                                    }
                                                    productWarnings += '<div class="py-3 px-3 d-flex ml-2 border warning-message-text" style="background-color: ' + warning.design.background + '"><div class="mr-3 d-flex align-items-center"><img src="' + warning.design.icon + '" width="50px"></div><div class="d-flex align-items-center"><div class="text-left warning-messages-block">' + warning.messages + '</div></div></div>';
                                                    selectedWarning.push({ warning });
                                                }
                                            }
                                        } else if (warning.targeted_product_type === 'variation') {//variation wise check
                                            const productsIds = warning.products_ids;
                                            if (productsIds.length > 0) {
                                                if (productsIds.includes(parseInt(cartVariationId))) {
                                                    if (productWarnings.length === 0) {
                                                        warningProductKeys.push(productKey);
                                                        productWarnings += '<div class="d-flex align-items-start justify-content-center warning-block-border"><div class="d-flex flex-column align-items-center justify-content-center" style="width: 120px;padding-top: 12px;border: 1px solid #ddd"><img src="' + item.cart_image + '" width="90%" /> <p style="padding: 5px;font-size: 11px;line-height: 1.2;text-align: center;margin-bottom: 0!important;">' + item.cart_product_name + ' - ' + item.cart_variation_name + '</p></div><div class="d-flex flex-column w-100">';
                                                    }
                                                    productWarnings += '<div class="py-3 px-3 d-flex ml-2 border warning-message-text" style="background-color: ' + warning.design.background + '"><div class="mr-3 d-flex align-items-center"><img src="' + warning.design.icon + '" width="50px"></div><div class="d-flex align-items-center"><div class="text-left warning-messages-block">' + warning.messages + '</div></div></div>';
                                                    selectedWarning.push({ warning });
                                                }
                                            }
                                        }
                                    }

                                }
                            });
                            if (productWarnings.length > 0) {
                                warningContents += productWarnings + '</div></div>';
                            }
                        });
                    }

                    //console.log('warningContents: ', warningContents);

                    if (warningContents.length > 0) {
                        this.setState({ warningProductCartKeys: warningProductKeys, selectedWarning: selectedWarning });

                        $("#warningModal").modal({
                            backdrop: 'static',
                            keyboard: false,
                        });
                        //let modalContent = '<div class="mt-2 warning-block-cards">'+warningContents+'</div>';
                        //let modalContent = '<div class="modal-header cus-modal-header"><button type="button" className="close" data-dismiss="modal" aria-label="Close" style="border: 0px;background: transparent;font-size: 25px;right: 0px;top: -5px;cursor: pointer"><span aria-hidden="true">&times;</span></button></div><div class="modal-body montserrat" style="background: '+this.state.warnings[0].design.background+'"><div class="py-3 d-flex align-items-center justify-content-center"><img src="'+this.state.warnings[0].design.icon+'" width="80px"></div><div class="py-3 px-3 text-center">'+this.state.warnings[0].messages+'</div><div class="d-flex align-items-center justify-content-center py-3"><button class="btn mr-4" style="background:'+this.state.warnings[0].buttons.cancel.background+'">Cancel</button><button class="btn btn-s">OK</button></div></div>';
                        //let modalContent = '<div class="modal-header cus-modal-header"><button type="button" className="close" data-dismiss="modal" aria-label="Close" style="border: 0px;background: transparent;font-size: 25px;right: 0px;top: -5px;cursor: pointer"><span aria-hidden="true">&times;</span></button></div><div class="modal-body montserrat" style="background: '+this.state.warnings[0].design.background+'"><div class="py-3 px-3 d-flex justify-content-center align-items-start"><div class="mr-3"><img src="'+this.state.warnings[0].design.icon+'" width="80px"></div><div class="text-center">'+this.state.warnings[0].messages+'</div></div><div class="d-flex align-items-center justify-content-center py-3"><button class="btn mr-4" style="background:'+this.state.warnings[0].buttons.cancel.background+'">Cancel</button><button class="btn btn-s">OK</button></div></div>';
                        //let modalContent = '<div class="modal-header cus-modal-header"><button type="button" className="close" data-dismiss="modal" aria-label="Close" style="border: 0px;background: transparent;font-size: 25px;right: 0px;top: -5px;cursor: pointer"><span aria-hidden="true">&times;</span></button></div><div class="modal-body montserrat" style="background: '+this.state.warnings[0].design.background+'"><div class="py-3 px-3 d-flex justify-content-center align-items-start"><div class="text-center">'+this.state.warnings[0].messages+'</div><div class="ml-3"><img src="'+this.state.warnings[0].design.icon+'" width="80px"></div></div><div class="d-flex align-items-center justify-content-center py-3"><button class="btn mr-4" style="background:'+this.state.warnings[0].buttons.cancel.background+'">Cancel</button><button class="btn btn-s">OK</button></div></div>';
                        //let modalContent = '<div class="modal-header cus-modal-header"><button type="button" className="close" data-dismiss="modal" aria-label="Close" style="border: 0px;background: transparent;font-size: 25px;right: 0px;top: -5px;cursor: pointer"><span aria-hidden="true">&times;</span></button></div><div class="modal-body montserrat" style="background: '+this.state.warnings[0].design.background+'"><div class="py-3 px-3 text-center">'+this.state.warnings[0].messages+'</div><div class="pt-2 pb-3 d-flex align-items-center justify-content-center"><img src="'+this.state.warnings[0].design.icon+'" width="80px"></div><div class="d-flex align-items-center justify-content-center py-3"><button class="btn mr-4" style="background:'+this.state.warnings[0].buttons.cancel.background+'">Cancel</button><button class="btn btn-success">OK</button></div></div>';
                        $("#warningModalContent").html(warningContents);
                    }else {
                        SET_STORAGE("california-warning", 'no');
                        SET_STORAGE("ny-order", 'no');
                    }
                }
            }
        });
    }

    componentDidMount() {
        document.querySelector("body").scrollIntoView();

        this.reApplyCoupon();
        this.getCart();
        this.getAllShippingMethods();
        this.getCountryList();

        if (this.state.isEnableHowYouKnow == 'yes') {
            this.getHowYouKnowOptions();
        }

        if (this.state.isAuthenticated) {
            this.getUserAdditionalData();
        }
        let cartMethodId = GET_STORAGE("cartID");
        SET_STORAGE("cartMethodId", cartMethodId);
        
        let recurringCartMethodId = GET_STORAGE("recurringCartId");
        SET_STORAGE("recurringCartMethodId", recurringCartMethodId);
    }

    getUserAdditionalData = () => {
        this.getBillingAddress();
        this.getShippingAddress();
        setTimeout(function () {
            klaviyoStartedCheckout();
            //this.addMemberToKlaviyoSubscriber();
        }.bind(this), 5000)
    }

    getHowYouKnowOptions = () => {
        AJAX_PUBLIC_REQUEST("POST", "user/getHowYouKnowOptions", {}).then(results => {
            if (parseInt(results.response.code) === 1000) {
                this.setState({ howYouKnowOptions: results.response.data })
            } else {
                this.setState({
                    howYouKnowOptions: [],
                    error: results.response.message
                })
            }
        });
    }

    // Get and calculate tax if applicable
    getTax = (checkStatus = 'no') => {

        this.setState({
            tax_amount: 0,
            tax_info: '',
            subscription_tax_amount: 0,
            subscription_tax_info: '',
            meal_tax_amount: 0,
            meal_tax_info: '',
            subscription_meal_tax_amount: 0,
            subscription_meal_tax_info: '',
        })

        if (this.state.taxStatus == 1) {
            let address_1 = '';
            let postcode = '';
            let city = '';
            let state = '';
            let country = '';
            if (checkStatus == "checked") {
                address_1 = document.getElementById("shipping_address_1").value;
                postcode = document.getElementById("shipping_postcode").value;
                city = document.getElementById("shipping_city").value;
                state = document.getElementById("shipping_state").value;
                country = document.getElementById("shipping_country").value;
            } else {
                address_1 = document.getElementById("billing_address_1").value;
                postcode = document.getElementById("billing_postcode").value;
                city = document.getElementById("billing_city").value;
                state = document.getElementById("billing_state").value;
                country = document.getElementById("billing_country").value;
            }

            if (address_1 != '' && postcode != '' && city != '' && state != '' && country != '') {

                let custom_bundle = GET_STORAGE("BundleCart") ? JSON.parse(GET_STORAGE("BundleCart")): [];
                if (custom_bundle) {
                    custom_bundle.forEach(item => {
                        if (item.hasOwnProperty('discount_data_new')) {
                            delete item.discount_data_new;
                        }
                    });
                }
                let cart = GET_STORAGE("cart") ? JSON.parse(GET_STORAGE("cart")) : [];
                let taxData = {
                    address_1: address_1,
                    postcode: postcode,
                    city: city,
                    state: state,
                    country: country,
                    shipping_method_id: this.state.shipping_method_id,
                    recurring_shipping_method_id: this.state.recurring_shipping_method_id,
                    cart_items: cart.length > 0 ? cart : custom_bundle, // set shoping carts
                    meals: JSON.parse(GET_STORAGE("meals")), // set meals
                    cart_coupons: JSON.parse(GET_STORAGE("coupon")),
                    is_custom_bundle: (custom_bundle.length > 0) ? 'yes' : 'no',
                    custom_bundle_subscription : (BUNDLE_CART_COUNT() != 0 ) ? GET_STORAGE("is_subscription") : 0,
                }

                AJAX_PUBLIC_REQUEST("POST", "order/getTax", taxData).then(results => {
                    if (parseInt(results.response.code) === 1000 && results.response.data != '') {
                        let data = results.response.data;
                        //TaxJar Ping Adjustments
                        SET_STORAGE("taxData", JSON.stringify(data));
                        this.setState({
                            tax_amount: (data.tax_amount) ? data.tax_amount : 0,
                            tax_info: data.tax_info,
                            subscription_tax_amount: (data.subscription_tax_amount) ? data.subscription_tax_amount : 0,
                            subscription_tax_info: data.subscription_tax_info,
                            meal_tax_amount: (data.meal_tax_amount) ? data.meal_tax_amount : 0,
                            meal_tax_info: data.meal_tax_info,
                            subscription_meal_tax_amount: (data.subscription_meal_tax_amount) ? data.subscription_meal_tax_amount : 0,
                            subscription_meal_tax_info: data.subscription_meal_tax_info,
                        });
                    }
                });
            }
        }
    }

    timeOut = (timedata) => {
        setTimeout(function () {
            this.setState({
                success_alert_wrapper_show: false,
            });
        }.bind(this), timedata);
    }

    getCart = () => {
        if ((ITEM_COUNT() === 0) && (MEAL_COUNT() === 0) && (BUNDLE_COUNT() === 0)) {
            history.push("/");
        }
        CHECK_STORAGE();
        if (GET_STORAGE('cart')) {
            this.setState({ items: JSON.parse(GET_STORAGE('cart')) });
        } else {
            this.setState({ items: [] });
        }
        if (GET_STORAGE('meals')) {
            let meals = JSON.parse(GET_STORAGE('meals'));
            if (meals.hasOwnProperty('items') && meals.items.length > 0) {
                this.setState({ meals: meals });
            } else {
                REMOVE_STORAGE('meals');
                this.setState({ meals: [] });
            }
        } else {
            this.setState({ meals: [] });
        }
        if (GET_STORAGE('coupon')) {
            this.setState({ coupons: JSON.parse(GET_STORAGE("coupon")) });
        } else {
            this.setState({ coupons: [] });
        }

        if (GET_STORAGE('BundleCart')) {
            this.setState({ bundle: JSON.parse(GET_STORAGE('BundleCart')) });
        } else {
            this.setState({ bundle: [] });
        }
    }

    getAllShippingMethods = () => {
        AJAX_PUBLIC_REQUEST("POST", "order/getShippingMethodList", {}).then(results => {
            if (parseInt(results.response.code) === 1000) {
                let methods = results.response.data;
                let method = methods.filter(c => c.id === GET_STORAGE("cartMethodId"));
                let method2 = methods.filter(c => c.id === GET_STORAGE("recurringCartMethodId"));
                if (this.state.shipping_method_id) {
                    if (this.state.shipping_method_id != GET_STORAGE("cartMethodId")) {
                        SET_STORAGE("cartMethodId", methods[0].id);
                    }
                }

                this.setState({
                    shippingMethods: methods,
                    shipping_method_id: GET_STORAGE("cartMethodId"),
                    // cartShippingCost: (this.state.shipping_method_id != GET_STORAGE("cartMethodId")) ? method[0].cost : methods[0].cost,
                    cartShippingCost: (GET_STORAGE("cartMethodId")) ? method[0].cost : methods[0].cost,
                });
                if (method2) {
                    this.setState({
                        recurring_shipping_method_id: GET_STORAGE("recurringCartMethodId"),
                        recurringCartShippingCost: method2[0].cost,
                    });
                }
            } else {
                this.setState({
                    error: results.response.message,
                })
            }
        });
    }

    getCountryList = () => {
        AJAX_PUBLIC_REQUEST("POST", "user/getCountry", {}).then(results => {
            if (parseInt(results.response.code) === 1000) {
                this.setState({
                    loading: false,
                    countryList: results.response.data
                });
            } else {
                this.setState({
                    loading: false,
                    error: results.response.message,
                })
            }
        });
    }

    getBillingAddress = () => {
        AJAX_REQUEST("POST", "user/billingDetails", {}).then(results => {
            if (parseInt(results.response.code) === 1000) {
                this.setState({
                    loading: false,
                    billing_first_name: results.response.data.billing_first_name,
                    billing_last_name: results.response.data.billing_last_name,
                    billing_company: results.response.data.billing_company,
                    billing_address_1: results.response.data.billing_address_1,
                    billing_address_2: results.response.data.billing_address_2,
                    billing_city: results.response.data.billing_city,
                    billing_postcode: results.response.data.billing_postcode,
                    billing_country: results.response.data.billing_country_id,
                    billing_state: results.response.data.billing_state,
                    billing_phone: results.response.data.billing_phone,
                    billing_email: results.response.data.billing_email
                });
                if (parseInt(results.response.data.billing_country_id) == 38 && CanadaRestrictedProduct()) {
                    $("#canadaRestrictedProductModal").modal({
                        backdrop: 'static',
                        keyboard: false,
                    });
                    $("#canadaRestrictedProductModalBody").html(CanadaRestrictedProduct());
                    this.setState({
                        billing_country: '',
                        billing_state: '',
                        billingStateList: [],
                    })
                }

                setTimeout(() => {
                    //load warning
                    this.warningModalHandel(this.state.billing_country, this.state.billing_state);

                    this.getBillingStateList(this.state.billing_country);
                }, 100);
            } else {
                this.setState({
                    loading: false,
                    error_meg: results.response.message,
                })
            }
        });
    }

    onChangeBillingCountry = (e) => {
        let countryId = e.target.value;
        if (!document.getElementById("ship_to_different_address").checked && parseInt(countryId) == 38 && CanadaRestrictedProduct()) {
            $("#canadaRestrictedProductModal").modal({
                backdrop: 'static',
                keyboard: false,
            });
            $("#canadaRestrictedProductModalBody").html(CanadaRestrictedProduct());
        } else {
            this.setState({ [e.target.name]: countryId })
            this.getBillingStateList(countryId);
        }
    }
    getBillingStateList = (countryId) => {
        let data = { country_id: countryId };
        AJAX_PUBLIC_REQUEST("POST", "user/getState", data).then(results => {
            if (parseInt(results.response.code) === 1000) {
                this.setState({ billingStateList: results.response.data });
            } else {
                this.setState({ billingStateList: [] })
            }

            setTimeout(() => {
                this.getTax();
            }, 100);
        });
    }

    getShippingAddress = () => {
        AJAX_REQUEST("POST", "user/shippingDetails", {}).then(results => {
            if (parseInt(results.response.code) === 1000) {
                this.setState({
                    loading: false,
                    shipping_first_name: results.response.data.shipping_first_name,
                    shipping_last_name: results.response.data.shipping_last_name,
                    shipping_company: results.response.data.shipping_company,
                    shipping_address_1: results.response.data.shipping_address_1,
                    shipping_address_2: results.response.data.shipping_address_2,
                    shipping_city: results.response.data.shipping_city,
                    shipping_postcode: results.response.data.shipping_postcode,
                    shipping_country: results.response.data.shipping_country_id,
                    shipping_state: results.response.data.shipping_state,
                });

                setTimeout(() => {
                    this.getShippingStateList(this.state.shipping_country);
                }, 100);

            } else {
                this.setState({
                    loading: false,
                    error_meg: results.response.message,
                })
            }
        });
    }
    onChangeShippingCountry = (e) => {
        let countryId = e.target.value;
        if (document.getElementById("ship_to_different_address").checked && parseInt(countryId) == 38 && CanadaRestrictedProduct()) {
            $("#canadaRestrictedProductModal").modal({
                backdrop: 'static',
                keyboard: false,
            });
            $("#canadaRestrictedProductModalBody").html(CanadaRestrictedProduct());
        } else {
            this.setState({ [e.target.name]: countryId })
            this.getShippingStateList(countryId);
        }
    }
    getShippingStateList = (countryId) => {
        let data = { country_id: countryId };
        AJAX_PUBLIC_REQUEST("POST", "user/getState", data).then(results => {
            if (parseInt(results.response.code) === 1000) {
                this.setState({ shippingStateList: results.response.data });
            } else {
                this.setState({ shippingStateList: [] })
            }
            setTimeout(() => {
                this.getTax();
            }, 100);
        });
    }

    changeCartShippingMethod = (e) => {
        this.setState({
            shipping_method_id: e.target.getAttribute('cid'),
            cartShippingCost: e.target.getAttribute('price'),
        })
        SET_STORAGE("cartMethodId", e.target.getAttribute('cid'))
    }

    changeRecurringCartShippingMethod = (e) => {
        this.setState({
            recurring_shipping_method_id: e.target.getAttribute('rid'),
            recurringCartShippingCost: e.target.getAttribute('price'),
        })
        SET_STORAGE("recurringCartMethodId", e.target.getAttribute('rid'))
    }

    continueWithoutVerify = (e) => {
        if (document.getElementById("continue_without_verify").checked) {
            this.setState({ continue_without_verify: 1 });
        } else {
            this.setState({ continue_without_verify: 0 });
        }
    }

    isDifferentShipping = (e) => {
        if (document.getElementById("ship_to_different_address").checked) {
            this.setState({
                ship_to_different_address: 'checked',
                shippingAreaVisible: true,
            });
            if ((parseInt(this.state.shipping_country) == 38) && CanadaRestrictedProduct()) {
                this.setState({
                    shipping_country: '',
                    shipping_state: '',
                    shippingStateList: [],
                })
            }
            //load warning
            this.warningModalHandel(this.state.shipping_country, this.state.shipping_state);

            this.getTax("checked");
        } else {
            this.setState({
                ship_to_different_address: 'no',
                shippingAreaVisible: false,
            });
            if ((parseInt(this.state.billing_country) == 38) && CanadaRestrictedProduct()) {
                this.setState({
                    billing_country: '',
                    billing_state: '',
                    billingStateList: [],
                })
            }
            //load warning
            this.warningModalHandel(this.state.billing_country, this.state.billing_state);

            this.getTax();
        }
    }
    agreeTermsPolicy = (e) => {
        if (document.getElementById("agreeTermsPolicy").checked) {
            this.setState({ agreeTermsPolicy: true });
        } else {
            this.setState({ agreeTermsPolicy: false });
        }
    }
    sendPromotionUpdate = (e) => {
        if (document.getElementById("send_promotion_update").checked) {
            this.setState({ send_promotion_update: "yes" });
        } else {
            this.setState({ send_promotion_update: "no" });
        }
    }

    onSubmitHandler = (e) => {
        e.preventDefault();

        this.setState({
            success_alert_wrapper_show: false,
            warning_wrapper_show: false,
            errors: {},
            isLoading: true,
            place_order_loading: true,
            server_message: ''
        })

        if (!this.state.isAuthenticated && this.state.affiliate_code === '') {
            this.setState({
                server_message: '<strong>Sorry</strong> You are not allowed to make any purchase on this site. To be able purchase, you must use the referral link from any of our affiliate.',
                isLoading: false,
                place_order_loading: false,
                isFormValid: false
            });
            document.querySelector("body").scrollIntoView();
            return;
        }

        let cartData = {
            // Billing Details
            billing_first_name: this.state.billing_first_name,
            billing_last_name: this.state.billing_last_name,
            billing_company: this.state.billing_company,
            billing_country: this.state.billing_country,
            billing_address_1: this.state.billing_address_1,
            billing_address_2: this.state.billing_address_2,
            billing_city: this.state.billing_city,
            billing_state: this.state.billing_state,
            billing_postcode: this.state.billing_postcode,
            billing_phone: this.state.billing_phone,
            billing_email: this.state.billing_email,
            // Different Shipping Address
            ship_to_different_address: this.state.ship_to_different_address,
            shipping_first_name: this.state.shipping_first_name,
            shipping_last_name: this.state.shipping_last_name,
            shipping_company: this.state.shipping_company,
            shipping_country: this.state.shipping_country,
            shipping_address_1: this.state.shipping_address_1,
            shipping_address_2: this.state.shipping_address_2,
            shipping_city: this.state.shipping_city,
            shipping_state: this.state.shipping_state,
            shipping_postcode: this.state.shipping_postcode,
            // Additional Information
            agree_to_receive_text: this.state.agree_to_receive_text,
            continue_without_verify: this.state.continue_without_verify,
            send_promotion_update: this.state.send_promotion_update,
            order_note: this.state.order_note,

            how_you_know: this.state.how_you_know,
            how_you_know_others: this.state.how_you_know_others,

            // Payment Method
            // payment_method: this.state.payment_method,
            // name_on_card: this.state.name_on_card,
            // expire_month: this.state.expire_month,
            // expire_year: this.state.expire_year,
            // card_number: CRYPTO_ENCRYPTION(this.state.card_number),
            // cvv: CRYPTO_ENCRYPTION(this.state.cvv),
            // Cart Information
            shipping_method_id: this.state.shipping_method_id,
            recurring_shipping_method_id: this.state.recurring_shipping_method_id,
            tax_amount: this.state.tax_amount,
            subscription_tax_amount: this.state.subscription_tax_amount,
            meal_tax_amount: this.state.meal_tax_amount,
            subscription_meal_tax_amount: this.state.subscription_meal_tax_amount,
            tax_info: this.state.tax_info,
            subscription_tax_info: this.state.subscription_tax_info,
            meal_tax_info: this.state.meal_tax_info,
            subscription_meal_tax_info: this.state.subscription_meal_tax_info,
            cart_items: (ITEM_COUNT() == 0) ? JSON.parse(GET_STORAGE("BundleCart")) : JSON.parse(GET_STORAGE("cart")),
            meals: JSON.parse(GET_STORAGE("meals")),
            cart_coupons: JSON.parse(GET_STORAGE("coupon")),
        }

        if (SITE == 'refer') {
            cartData.affiliate_code = this.state.affiliate_code;
            cartData.affiliate_code_created_date = this.state.affiliate_code_created_date;
        }

        const val_return = checkoutValidate(cartData);
        this.setState(val_return);

        if (!document.getElementById("ship_to_different_address").checked) {
            cartData.shipping_first_name = this.state.billing_first_name;
            cartData.shipping_last_name = this.state.billing_last_name;
            cartData.shipping_company = this.state.billing_company;
            cartData.shipping_country = this.state.billing_country;
            cartData.shipping_address_1 = this.state.billing_address_1;
            cartData.shipping_address_2 = this.state.billing_address_2;
            cartData.shipping_city = this.state.billing_city;
            cartData.shipping_state = this.state.billing_state;
            cartData.shipping_postcode = this.state.billing_postcode;

        }
        createKlaviyoIdentify();
        if (this.state.isAuthenticated === false && this.state.user_email) {
            cartData.user_email = this.state.user_email
        }
        if (cartData && cartData.cart_items && Array.isArray(cartData.cart_items)) {
            cartData.cart_items.forEach(item => {
                // Check if the item has discount_data_new property
                if (item.hasOwnProperty('discount_data_new')) {
                    delete item.discount_data_new;
                }
            });
        }
        if (val_return.isValid) {
            SET_STORAGE("billing_data", JSON.stringify(cartData));
            history.push('/checkout-shipping');
            // this.props.checkoutRequest(cartData).then(results => {
            //     if (parseInt(results.response.code) === 1000) {
            //         DESTROY_CART(); // Destroy cart information from localStorage
            //         DELETE_COOKIE('af');
            //         DELETE_COOKIE('af_created_date');
            //         this.setState({
            //             server_message: results.response.message,
            //             isLoading: false,
            //             place_order_loading: false,
            //             isFormValid: false
            //         });
            //         document.querySelector("body").scrollIntoView();

            //         if (this.state.isAuthenticated === false && this.state.user_email) {
            //             this.props.setUserLogin(results.response.user_data) //set login data


            //         }

            //         SET_STORAGE(ORDER_RECEIVED, 'yes'); //Set just receive a order by athore. do not delete that line
            //         history.push('/order-received/' + results.response.data.order_id);
            //     }
            //     // Check product stockout
            //     else if (parseInt(results.response.code) === 3004) {

            //         this.setState({
            //             isLoading: false,
            //             place_order_loading: false,
            //             isFormValid: false,
            //             stockoutErrorMessage: results.response.message,
            //         });

            //         $("#stockoutErrorModal").modal({
            //             backdrop: 'static',
            //             keyboard: false,
            //         });

            //     }
            //     else {
            //         if (parseInt(results.response.code) === 3001) {
            //             this.setState({ addressVerifyCode: true })
            //         }

            //         this.setState({
            //             server_message: results.response.message,
            //             isLoading: false,
            //             place_order_loading: false,
            //             isFormValid: false
            //         });
            //         document.querySelector("body").scrollIntoView();
            //     }
            // });
        } else {
            this.setState({
                isLoading: false,
                place_order_loading: false,
                server_message: 'Please fix the following error(s).',
                isFormValid: false
            });
            document.querySelector("body").scrollIntoView();
        }
    }

    applyCoupon = (e) => {
        e.preventDefault();
        let couponCode = (this.state.coupon_code).trim();

        this.setState({
            isFormValid: true,
            coupon_loading: true,
            success_alert_wrapper_show_coupon: false
        })

        if (couponCode == '' || couponCode == null) {
            this.setState({
                server_message: "The coupon code field is required.",
                isLoading: false,
                coupon_loading: false,
                isFormValid: false,
            });
        } else {

            let couponExists = false;
            let exCouponList = [];
            if (!GET_STORAGE("coupon")) {
                SET_STORAGE("coupon", JSON.stringify(exCouponList));
            }
            exCouponList = JSON.parse(GET_STORAGE("coupon"));

            if (exCouponList.length > 0) {
                exCouponList.forEach(function (exCoupon, key) {
                    if (exCoupon.coupon_code.toUpperCase() === couponCode.toUpperCase()) {
                        couponExists = true;
                    }
                });
            }

            if (!couponExists) {
                let applyCouponCode = [];
                if (exCouponList.length > 0) {
                    exCouponList.forEach(function (couponData, key) {
                        applyCouponCode.push(couponData.coupon_code);
                    });
                }
                applyCouponCode.push(couponCode);

                let data = {
                    coupon_code: applyCouponCode,
                    cart_items: JSON.parse(GET_STORAGE("cart")),
                    meals: JSON.parse(GET_STORAGE("meals")),
                }

                AJAX_PUBLIC_REQUEST("POST", "coupon/applyCoupon", data).then(results => {
                    if (parseInt(results.response.code) === 1000) {

                        this.setState({
                            success_alert_wrapper_show_coupon: false,
                            coupon_loading: false,
                            coupon_code: '',
                            server_message: results.response.message,
                            success_alert_wrapper_show: true
                        })

                        let couponResponse = results.response.data;
                        if (couponResponse || couponResponse != '') {
                            exCouponList = [];
                            couponResponse.forEach(function (couponData, key) {
                                exCouponList.push(couponData);
                            });
                        }

                        SET_STORAGE("coupon", JSON.stringify(exCouponList));
                        this.getAllShippingMethods();
                        this.getCart();
                        this.getTax();
                    } else {
                        this.setState({
                            server_message: results.response.message,
                            isLoading: false,
                            coupon_loading: false,
                            isFormValid: false,
                            error: results.response.message,
                        });
                    }
                    this.timeOut(5000);
                });
            } else {
                this.setState({
                    server_message: "The coupon code already applied.",
                    isLoading: false,
                    coupon_loading: false,
                    isFormValid: false,
                });
            }
        }
    }

    reApplyCoupon = () => {
        if (GET_STORAGE("coupon")) {
            let exCouponList = JSON.parse(GET_STORAGE("coupon"));
            let coupon_code = [];
            exCouponList.forEach(function (couponData, key) {
                coupon_code.push(couponData.coupon_code);
            });
            let data = {
                coupon_code: coupon_code,
                cart_items: JSON.parse(GET_STORAGE("cart")),
                meals: JSON.parse(GET_STORAGE("meals")),
            }
            AJAX_PUBLIC_REQUEST("POST", "coupon/applyCoupon", data).then(results => {
                if (parseInt(results.response.code) === 1000) {
                    SET_STORAGE("coupon", JSON.stringify(results.response.data));
                } else if (parseInt(results.response.code) === 4000) {
                    REMOVE_STORAGE('coupon')
                }
                this.getAllShippingMethods();
                this.getTax();
                this.getCart();
            });
        }
    }

    deleteCoupon = (e, row_id) => {
        e.preventDefault();
        if (window.confirm("Are you sure want to delete coupon?")) {
            let coupon = JSON.parse(GET_STORAGE('coupon'));
            if (coupon.splice(row_id, 1)) {
                SET_STORAGE("coupon", JSON.stringify(coupon));
                this.setState({
                    freeShipping: 0,
                    mealShippingCostStatus: true,
                    server_message: 'The coupon code successfully removed.',
                    success_alert_wrapper_show_coupon: false,
                    coupon_code: '',
                    success_alert_wrapper_show: true
                });
                document.querySelector("body").scrollIntoView();
                this.timeOut(5000);
                this.reApplyCoupon();
                this.getAllShippingMethods();
                this.getCart();
            }
        }
    }

    showCouponAction = (e) => {
        e.preventDefault();
        this.setState({
            show_coupon: this.state.show_coupon ? false : true
        })
    }

    backToCart = () => {
        $('#stockoutErrorModal').modal('hide');
        history.push('/cart');
    }

    addMemberToKlaviyoSubscriber = () => {
        let data = {
            email: this.props.auth.user.email
        }
        AJAX_REQUEST("POST", "order/addMemberToKlaviyoSubscriber", data).then(results => {
            if (parseInt(results.response.code) === 1000) {
                // success
            }
        });
    }

    addWarning = () => {
        const { selectedWarning } = this.state;
        if (selectedWarning.length > 0) {
            selectedWarning.forEach(selectedWarn => {
                Object.keys(selectedWarn.warning.locations).forEach(key => {
                    const locationsArray = selectedWarn.warning.locations[key];
                    if (locationsArray.includes('NY')) {
                        SET_STORAGE("ny-order", 'yes');
                        SET_STORAGE("california-warning", 'no');
                    } else if (locationsArray.includes('CA')) {
                        SET_STORAGE("california-warning", 'yes');
                        SET_STORAGE("ny-order", 'no');
                    }
                });
            });
        }
    };    

    render() {
        var efa_status = false;
        var meal_status = false;
        const { errors, server_message, show_coupon, coupon_code_button_loading, success_alert_wrapper_show, warning_wrapper_show, place_order_loading, captchaCode } = this.state;
        const errors_data = server_message;

        let duration_id = 1;
        let duration_text = "Every 1 week";
        if (this.state.meals && this.state.meals.duration_id) {
            duration_id = parseInt(this.state.meals.duration_id);
        }
        if (this.state.meals && this.state.meals.duration_text) {
            duration_text = this.state.meals.duration_text;
        }

        let cartTotal = 0;
        if (this.state.items.length > 0) {
            if (this.state.meals != null && this.state.meals != '' && this.state.meals.items.length > 0 && this.state.mealShippingCostStatus == true) {
                cartTotal = Number(CART_SUB_TOTAL()) + Number(MEAL_TOTAL()) + Number(this.state.cartShippingCost) + Number(this.state.tax_amount) + Number(this.state.meal_tax_amount) - Number(COUPON_TOTAL());
            } else {
                cartTotal = Number(CART_SUB_TOTAL()) + Number(MEAL_SUB_TOTAL()) + Number(this.state.cartShippingCost) + Number(this.state.tax_amount) - Number(COUPON_TOTAL());
            }
        } else if(this.state.bundle != null) {
            if(this.state.bundle.length > 0){
                cartTotal = Number(BUNDLE_SUB_TOTAL()) + Number(MEAL_SUB_TOTAL()) + Number(this.state.cartShippingCost) + Number(this.state.tax_amount);
            }
        } 
        else {
            if (this.state.meals != null && this.state.meals != '' && this.state.meals.items.length > 0 && this.state.mealShippingCostStatus == true) {
                cartTotal = Number(MEAL_TOTAL()) + Number(this.state.meal_tax_amount) - Number(COUPON_TOTAL());
                meal_status = true;
            } else {
                cartTotal = Number(MEAL_SUB_TOTAL()) + Number(this.state.meal_tax_amount) - Number(COUPON_TOTAL());
            }
        }
        this.setState({ cartTotal: cartTotal })

        this.state.items.forEach(function (item, key) {
            if (item.cart_product_name == "EFA") {
                efa_status = true;
            }
        });
        return (
            <Fragment>
                {
                    (this.state.loading) ?
                        <div className='loading container full_page_loader'></div>
                        :
                        <Fragment>
                            {
                                ((this.state.shipping_country == "CA" && efa_status) || ((this.state.shipping_state == "AK" || this.state.shipping_state == "HI") && meal_status)) ?
                                    <PreventCheckoutModal productLink="#" /> : ''
                            }
                            <div className="checkout_page">
                                <div className="container-fluid form_input_blk">
                                    <Row>
                                        <Col md={8} className="checkout_left_blk white_bg">
                                            <AlertWrapper errors_data={errors_data} isFormValid={this.state.isFormValid} />
                                            <AlertWrapperSuccess errors_data={errors_data} success_alert_wrapper_show={success_alert_wrapper_show} />
                                            <AlertWrapperWarning errors_data={errors_data} warning_wrapper_show={warning_wrapper_show} />

                                            {
                                                (!this.state.addressVerifyCode) ? ''
                                                    :
                                                    <Fragment>
                                                        <p className="promotion_and_product_updates addess-verification w-100 m-0">
                                                            <label className="checkbox_label m-0 d-flex align-items-center">
                                                                <input onClick={this.continueWithoutVerify} id='continue_without_verify' className="" type="checkbox" />
                                                                <span className="checbox"></span>
                                                                <span className="d-block font_14 text_black fw_ar_reg pl_10">Continue without verify address?</span>
                                                            </label>
                                                        </p>
                                                    </Fragment>
                                            }
                                            <NavLink to={'/cart'} className="d-flex back_navigation">
                                                <img src={LeftArrow} />
                                                <span className="d-block pl_4 font_12 text_black fw_ar_reg">Return to cart</span>
                                            </NavLink>
                                            <div className="text-center mb_31">
                                                <img src={CheckoutLogo} alt="checkout logo" />
                                            </div>
                                            <ul className="checkout_ul w-100 d-flex align-items-center mb_27">
                                                <li className='d-flex align-items-center justify-content-center active'>
                                                    <span className="d-flex align-items-center justify-content-center white_bg font_11 text_black checkout_round fw_ar_reg">1</span>
                                                    <span className="font_16 d-block text_black fw_ar_reg pl_10">Information</span>
                                                </li>
                                                <li className='d-flex align-items-center justify-content-center'>
                                                    <span className="d-flex align-items-center justify-content-center white_bg font_11 text_black checkout_round fw_ar_reg">2</span>
                                                    <span className="font_16 d-block text_black fw_ar_reg pl_10">Shipping</span>
                                                </li>
                                                <li className='d-flex align-items-center justify-content-center'>
                                                    <span className="d-flex align-items-center justify-content-center white_bg font_11 text_black checkout_round fw_ar_reg">3</span>
                                                    <span className="font_16 d-block text_black fw_ar_reg pl_10">Payment</span>
                                                </li>
                                            </ul>
                                            <span className="d-block text-center text_black font_12 fw_ar_reg">Step 1</span>
                                            <h4 className="font_16 text-center text_black mb_41 fw_ar_reg">Information</h4>
                                            <div className="checout_blk">
                                                {
                                                    this.state.isAuthenticated === false ?
                                                        <CheckoutLogin
                                                            checkOutLoginHandler={this.checkOutLoginHandler}
                                                            checkoutEmailCheckHandler={this.checkoutEmailCheckHandler}
                                                        />
                                                        : ''
                                                }
                                                <form id="orderForm" className={this.state.isFormDisabled === true ? 'orderForm-position mt_15' : ''} onSubmit={this.onSubmitHandler}>

                                                    {
                                                        this.state.isFormDisabled === true ?
                                                            <div className='overflow-block'></div>
                                                            : ''
                                                    }

                                                    <div className="w-100">

                                                        {/* Start Billing Address */}
                                                        <h3 className="font_16 text_black mb_8 fw_ar_reg">Contact Information</h3>
                                                        <Row className="checkout_row mb_32">
                                                            <Col md={12}>
                                                                <div className="mb_23">
                                                                    <input disabled={this.state.isFormDisabled} onChange={this.changeHandler} value={this.state.billing_email} type="text" className={classnames("form-control height_42", { 'pl_error_input': errors.billing_email })} name="billing_email" placeholder="Email Address" />
                                                                        <div className="d-flex align-items-center mt_11">
                                                                            <label html="" className='m-0 checkbox_label d-flex align-items-center'>
                                                                                <input disabled={this.state.isFormDisabled} onClick={this.sendPromotionUpdate} id='send_promotion_update' defaultChecked="checked" className="" type="checkbox" />
                                                                                <span className="checbox"></span>
                                                                                <span className="d-block checkbox_span pl_6 font_12 text_black">Email me with news and offers</span>
                                                                            </label>
                                                                        </div>
                                                                </div>
                                                            </Col>

                                                            
                                                            
                                                        </Row>
                                                        <h3 className="font_16 text_black mb_8 fw_ar_reg">Billing Address</h3>
                                                        <Row>
                                                            <Col md={4}>
                                                                <input disabled={this.state.isFormDisabled} placeholder="First Name" onChange={this.changeHandler} value={this.state.billing_first_name} type="text" className={classnames("form-control height_42", { 'pl_error_input': errors.billing_first_name })} name="billing_first_name" />
                                                            </Col>
                                                            <Col md={4}>
                                                                <input disabled={this.state.isFormDisabled} onChange={this.changeHandler} value={this.state.billing_last_name} type="text" className={classnames("form-control height_42", { 'pl_error_input': errors.billing_last_name })} name="billing_last_name" placeholder="Last Name" />
                                                            </Col>
                                                            <Col md={4}>
                                                                <div className="mb_23">
                                                                    <select disabled={this.state.isFormDisabled} onChange={this.onChangeBillingCountry} value={this.state.billing_country} name="billing_country" id="billing_country" className={classnames("form-control height_42", { 'pl_error_input': errors.billing_country })}>
                                                                        <option value="">Select a country…</option>
                                                                        {/* Start billing country list*/}
                                                                        {
                                                                            (this.state.countryList.length <= 0) ? null :
                                                                                this.state.countryList.map(function (country, key) {
                                                                                    return (
                                                                                        (parseInt(country.id) == 38 && MEAL_COUNT() != 0) ? "" :
                                                                                            <option key={key} value={country.id}>{country.name}</option>
                                                                                    )
                                                                                }.bind(this))
                                                                        }
                                                                        {/* End billing country list */}
                                                                    </select>
                                                                </div>
                                                            </Col>
                                                            <Col md={12}>
                                                                <div className="mb_23">
                                                                    <input disabled={this.state.isFormDisabled} onChange={this.changeHandler} value={this.state.billing_company} type="text" className={classnames("form-control height_42", { 'pl_error_input': errors.billing_company })} name="billing_company" placeholder="Company Name" />
                                                                    {
                                                                        (errors.billing_company) ?
                                                                            <span className="error-msg">{errors.billing_company}</span>
                                                                            : ""
                                                                    }
                                                                </div>
                                                            </Col>
                                                            <Col md={12}>
                                                                <div className="mb_23">
                                                                    <input disabled={this.state.isFormDisabled} onChange={this.changeHandler} onBlur={this.changeHandlerWithCallTax} value={this.state.billing_address_2} type="text" className={classnames("form-control height_42", { 'pl_error_input': errors.billing_address_2 })} name="billing_address_2" id="billing_address_2" placeholder="Apartment, suite, unit etc. (optional)" />
                                                                    {
                                                                        (errors.billing_address_2_msg) ?
                                                                            <span className="error-msg">{errors.billing_address_2_msg}</span>
                                                                            : ""
                                                                    }
                                                                </div>
                                                            </Col>
                                                            <Col md={12}>
                                                                <div className="mb_23">
                                                                    <input disabled={this.state.isFormDisabled} onChange={this.changeHandler} onBlur={this.changeHandlerWithCallTax} value={this.state.billing_address_1} type="text" className={classnames("form-control height_42", { 'pl_error_input': errors.billing_address_1 })} name="billing_address_1" id="billing_address_1" placeholder="House number and street name" />
                                                                    {
                                                                        (errors.billing_address_1_msg) ?
                                                                            <span className="error-msg">{errors.billing_address_1_msg}</span>
                                                                            : ""
                                                                    }
                                                                </div>
                                                            </Col>
                                                            <Col md={12}>
                                                                <div className="mb_23">
                                                                    <input disabled={this.state.isFormDisabled} onChange={this.changeHandler} onBlur={this.changeHandlerWithCallTax} value={this.state.billing_city} type="text" className={classnames("form-control height_42", { 'pl_error_input': errors.billing_city })} name="billing_city" id="billing_city" placeholder="Town / City" />
                                                                </div>
                                                            </Col>
                                                            <Col md={12}>
                                                                <div className="mb_23">
                                                                    <select disabled={this.state.isFormDisabled} onChange={this.changeHandlerWithCallTax} value={this.state.billing_state} name="billing_state" id="billing_state" className={classnames("form-control height_42", { 'pl_error_input': errors.billing_state })}>
                                                                        <option value="">Select a state...</option>
                                                                        {/* Start billing state list*/}
                                                                        {
                                                                            (this.state.billingStateList.length <= 0) ? null :
                                                                                this.state.billingStateList.map(function (state, key) {
                                                                                    return (
                                                                                        <option key={key} value={state.code}>{state.name}</option>
                                                                                    )
                                                                                }.bind(this))
                                                                        }
                                                                        {/* End billing state list */}
                                                                    </select>
                                                                </div>
                                                            </Col>
                                                            <Col md={12}>
                                                                <div className="mb_23">
                                                                    <input disabled={this.state.isFormDisabled} onChange={this.changeHandler} onBlur={this.changeHandlerWithCallTax} value={this.state.billing_postcode} type="text" className={classnames("form-control height_42", { 'pl_error_input': errors.billing_postcode })} name="billing_postcode" id="billing_postcode" placeholder="Zip Code" />
                                                                </div>
                                                            </Col>
                                                            <Col md={12}>
                                                                <div className="mb_12">
                                                                    <input disabled={this.state.isFormDisabled} onChange={this.changeHandler} value={this.state.billing_phone} type="text" className={classnames("form-control height_42", { 'pl_error_input': errors.billing_phone })} name="billing_phone" id="billing_phone" placeholder="phone" />
                                                                </div>
                                                            </Col>
                                                            <Col md={12}>
                                                                <div className="mb_12">
                                                                    <label className='checkbox_label d-flex align-items-center m-0'>
                                                                        {/* <input disabled={this.state.isFormDisabled} onClick={this.sendPromotionUpdate} id='send_promotion_update' className="" type="checkbox" /> */}
                                                                        <input disabled={this.state.isFormDisabled} onClick={this.changeHandler} type="checkbox" name="agree_to_receive_text" id="agree_to_receive_text" defaultChecked="checked" />
                                                                        <span className="checbox"></span>
                                                                        <span className="d-flex font_16 text_black fe_ar_reg pl_6"> Text me with news and offers </span>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col md={12}>
                                                                <div className="mb_12 mt_30">
                                                                    <label className='checkbox_label d-flex align-items-center m-0'>
                                                                        <input disabled={this.state.isFormDisabled} onClick={this.isDifferentShipping} value={this.state.ship_to_different_address} name="ship_to_different_address" id="ship_to_different_address" className="" type="checkbox" />
                                                                        <span className="checbox"></span>
                                                                        <span className="d-flex font_16 text_black fe_ar_reg pl_6">Ship to a different address?</span>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            {/* <Col md={12}>
                                                                <div className="form-group checkout_label">
                                                                    <label className="">I agree to receive text communications from Prestige Labs. Message and Data rates may apply</label>
                                                                    <div className="d-flex align-items-center">
                                                                        <span className="checbox"></span>
                                                                        <span className="d-flex font_16 text_black fe_ar_reg pl_6">
                                                                            <label ><input disabled={this.state.isFormDisabled} onClick={this.changeHandler} type="checkbox" name="agree_to_receive_text" id="agree_to_receive_text" defaultChecked="checked" /> Text me with news and offers </label>
                                                                        </span>
                                                                        <label htmlFor="" className="d-flex align-items-center checkbox_label radio_label m-0 mr_32">
                                                                            <input disabled={this.state.isFormDisabled} onChange={this.changeHandler} type="radio" name="agree_to_receive_text" value="yes" defaultChecked="checked" />
                                                                            <span className="checbox"></span>
                                                                            <span className="pl_6 text_black font_16 fw_ar_reg">Yes</span>
                                                                        </label>
                                                                        <label htmlFor="" className="d-flex align-items-center checkbox_label radio_label m-0 mr_32">
                                                                            <input disabled={this.state.isFormDisabled} onChange={this.changeHandler} type="radio" name="agree_to_receive_text" value="no" />
                                                                            <span className="checbox"></span>
                                                                            <span className="pl_6 text_black font_16 fw_ar_reg">No</span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </Col> */}
                                                        </Row>

                                                        {/* End Billing Address */}

                                                        {/* Start Shipping Address */}

                                                        <div style={{ display: this.state.shippingAreaVisible ? '' : 'none' }} className="woocommerce-billing-fields__field-wrapper">
                                                            <div className="mb_23 pull-left name_field">
                                                                <label className="dis_checkout_label">First name <span className="required">*</span></label>
                                                                <input disabled={this.state.isFormDisabled} onChange={this.changeHandler} value={this.state.shipping_first_name} type="text" className={classnames("form-control height_42", { 'pl_error_input': errors.shipping_first_name })} name="shipping_first_name" placeholder="" />
                                                            </div>
                                                            <div className="mb_23 pull-right name_field">
                                                                <label className="dis_checkout_label">Last name <span className="required">*</span></label>
                                                                <input disabled={this.state.isFormDisabled} onChange={this.changeHandler} value={this.state.shipping_last_name} type="text" className={classnames("form-control height_42", { 'pl_error_input': errors.shipping_last_name })} name="shipping_last_name" placeholder="" />
                                                            </div>
                                                            <div className="mb_23">
                                                                <label className="dis_checkout_label">Company name <span className="optional">(optional)</span></label>
                                                                <input disabled={this.state.isFormDisabled} onChange={this.changeHandler} value={this.state.shipping_company} type="text" className={classnames("form-control height_42", { 'pl_error_input': errors.shipping_company })} name="shipping_company" placeholder="" />
                                                            </div>
                                                            <div className="mb_23">
                                                                <label className="dis_checkout_label">Country <span className="required">*</span> </label>
                                                                <select disabled={this.state.isFormDisabled} onChange={this.onChangeShippingCountry} value={this.state.shipping_country} name="shipping_country" id="shipping_country" className={classnames("form-control height_42", { 'pl_error_input': errors.shipping_country })}>
                                                                    <option value="">Select a country…</option>
                                                                    {/* Start shipping country list*/}
                                                                    {
                                                                        (this.state.countryList.length <= 0) ? null :
                                                                            this.state.countryList.map(function (country, key) {
                                                                                return (
                                                                                    (parseInt(country.id) == 38 && MEAL_COUNT() != 0) ? "" :
                                                                                        <option key={key} value={country.id}>{country.name}</option>
                                                                                )
                                                                            }.bind(this))
                                                                    }
                                                                    {/* End shipping country list */}
                                                                </select>
                                                            </div>
                                                            <div className="mb_23">
                                                                <label className="dis_checkout_label">Street Address <span className="required">*</span> </label>
                                                                <input disabled={this.state.isFormDisabled} onChange={this.changeHandler} onBlur={this.changeHandlerWithCallTax} value={this.state.shipping_address_1} type="text" className={classnames("form-control height_42", { 'pl_error_input': errors.shipping_address_1 })} name="shipping_address_1" id="shipping_address_1" placeholder="House number and street name" />
                                                                {
                                                                    (errors.shipping_address_1_msg) ?
                                                                        <span className="error-msg">{errors.shipping_address_1_msg}</span>
                                                                        : ""
                                                                }
                                                            </div>
                                                            <div className="mb_23">
                                                                <input disabled={this.state.isFormDisabled} onChange={this.changeHandler} onBlur={this.changeHandlerWithCallTax} value={this.state.shipping_address_2} type="text" className={classnames("form-control height_42", { 'pl_error_input': errors.shipping_address_2 })} name="shipping_address_2" id="shipping_address_2" placeholder="Apartment, suite, unit etc. (optional)" />
                                                                {
                                                                    (errors.shipping_address_2_msg) ?
                                                                        <span className="error-msg">{errors.shipping_address_2_msg}</span>
                                                                        : ""
                                                                }
                                                            </div>

                                                            <div className="mb_23">
                                                                <label className="dis_checkout_label">Town / City <span className="required">*</span> </label>
                                                                <input disabled={this.state.isFormDisabled} onChange={this.changeHandler} onBlur={this.changeHandlerWithCallTax} value={this.state.shipping_city} type="text" className={classnames("form-control height_42", { 'pl_error_input': errors.shipping_city })} name="shipping_city" id="shipping_city" placeholder="" />
                                                            </div>
                                                            <div className="mb_23">
                                                                <label className="dis_checkout_label">State <span className="required">*</span> </label>
                                                                <select disabled={this.state.isFormDisabled} onChange={this.changeHandlerWithCallTax} value={this.state.shipping_state} name="shipping_state" id="shipping_state" className={classnames("form-control height_42", { 'pl_error_input': errors.shipping_state })}>
                                                                    <option value="">Select a state</option>
                                                                    {/* Start shipping state list*/}
                                                                    {
                                                                        (this.state.shippingStateList.length <= 0) ? null :
                                                                            this.state.shippingStateList.map(function (state, key) {
                                                                                return (
                                                                                    <option key={key} value={state.code}>{state.name}</option>
                                                                                )
                                                                            }.bind(this))
                                                                    }
                                                                    {/* End shipping state list */}
                                                                </select>
                                                            </div>
                                                            <div className="mb_23">
                                                                <label className="dis_checkout_label">ZIP <span className="required">*</span> </label>
                                                                <input disabled={this.state.isFormDisabled} onChange={this.changeHandler} onBlur={this.changeHandlerWithCallTax} value={this.state.shipping_postcode} type="text" className={classnames("form-control height_42", { 'pl_error_input': errors.shipping_postcode })} name="shipping_postcode" id="shipping_postcode" placeholder="" />
                                                            </div>
                                                        </div>

                                                        {/* End Shipping Address */}

                                                        {/* <div className="mb_23" >
            <label className="dis_checkout_label">Order notes <span className="optional">(optional)</span></label>
            <textarea onChange={this.changeHandler} value={this.state.order_note} name="order_note" className={classnames("form-control height_42", { 'pl_error_input': errors.order_note })} placeholder="Notes about your order, e.g. special notes for delivery." rows="2" cols="5">{this.state.order_note}</textarea>
        </div> */}



                                                        {
                                                            // start How did you hear about us? 
                                                            (this.state.isEnableHowYouKnow == "yes") ?
                                                                <Fragment>
                                                                    <div className="mb_23">
                                                                        <label className="dis_checkout_label">How'd you hear about us? <span className="required">*</span> </label>
                                                                        <select disabled={this.state.isFormDisabled} onChange={this.howKnowChangeHandler} value={this.state.how_you_know} name="how_you_know" id="how_you_know" className="form-control height_42" required>
                                                                            <option value="">Select One</option>
                                                                            {
                                                                                (this.state.howYouKnowOptions.length <= 0) ? null :
                                                                                    this.state.howYouKnowOptions.map(function (knowOption, key) {
                                                                                        return (
                                                                                            <option key={key} value={knowOption.option}>{knowOption.option}</option>
                                                                                        )
                                                                                    }.bind(this))
                                                                            }
                                                                            <option key={Math.random()} value="others">Others</option>
                                                                        </select>
                                                                    </div>
                                                                    {
                                                                        (this.state.isKnowOthers) ?
                                                                            <div className="mb_23">
                                                                                <textarea disabled={this.state.isFormDisabled} onChange={this.changeHandler} value={this.state.how_you_know_others} name="how_you_know_others" className="form-control height_42" required placeholder="Others" rows="1" cols="2">{this.state.how_you_know_others}</textarea>
                                                                            </div>
                                                                            : ""
                                                                    }

                                                                </Fragment>
                                                                : ""
                                                            // end How did you hear about us? 
                                                        }


                                                        <div className="clearfix"></div>
                                                    </div>

                                                    {/* Start cart view */}


                                                </form>
                                            </div>
                                        </Col>
                                        <Col md={4} className="checkout_rgt_blk">
                                            <form id="orderForm" className={this.state.isFormDisabled === true ? 'w-100' : ''} onSubmit={this.onSubmitHandler}>
                                                <div className="cart_summary_blk white_bg mb_41">
                                                    <div className="d-flex align-items-center mb_18">
                                                        <img src={CartSummaryIcon} alt="cart summary icon" />
                                                        <span className="d-block pl_6 font_14 let_spa_2 text_black">Cart Summary</span>
                                                    </div>
                                                    {
                                                        (this.state.bundle == null) ? null :
                                                        (this.state.bundle.length <= 0) ? null :
                                                            <Fragment>
                                                                <div className="cart_pro_su d-flex align-items-start justify-content-between">
                                                                    <div className="left_blk">
                                                                        <span className="d-block text_black left_spa_2 font_16 fw_el_bold">Product Bundle</span>
                                                                    </div>
                                                                    <div className="right_blk">
                                                                        <span className="d-block font_14 fw_ar_bold text_black text-right">
                                                                            {CURRENCY_FORMAT(BUNDLE_SUB_TOTAL())}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </Fragment>
                                                    }
                                                    {
                                                        (this.state.items.length <= 0) ? null :
                                                            this.state.items.map(function (item, key) {
                                                                return (
                                                                    <Fragment key={key}>
                                                                        <div className="cart_pro_su d-flex align-items-start justify-content-between">
                                                                            <div className="left_blk">
                                                                                <span className="d-block text_black left_spa_2 font_16 fw_el_bold">{item.cart_product_name}</span>
                                                                                <span className="d-block items_text left_spa_2 font_12 fw_ar_reg">{item.cart_variation_name}</span>
                                                                            </div>
                                                                            <div className="right_blk">
                                                                                <span className="d-block font_14 fw_ar_bold text_black text-right">
                                                                                    {
                                                                                        (item.subscription == "yes") ?
                                                                                            CURRENCY_FORMAT(item.quantity * item.cart_discount_price)
                                                                                            :
                                                                                            CURRENCY_FORMAT(item.quantity * item.cart_sale_price)
                                                                                    }
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </Fragment>
                                                                );
                                                            }.bind(this))
                                                    }
                                                    <ul className="pro_cart_det mt_30">
                                                        {
                                                            (this.state.items.length <= 0) ? '' :
                                                            <li className="d-flex align-items-center flex-wrap mb_7">
                                                                <span className="total_span let_spa_2 d-block font_14 fw_ar_reg">Sub Total</span>
                                                                <span className="price_span let_spa_2 text-right d-block font_14 fw_ar_reg">{CURRENCY_FORMAT(Number(CART_SUB_TOTAL()) + Number(MEAL_SUB_TOTAL()))}</span>
                                                            </li>
                                                        }
                                                        {
                                                            (this.state.bundle == null) ? '' :
                                                            (this.state.bundle.length <= 0) ? '' :
                                                            <li className="d-flex align-items-center flex-wrap mb_7">
                                                                <span className="total_span let_spa_2 d-block font_14 fw_ar_reg">Sub Total</span>
                                                                <span className="price_span let_spa_2 text-right d-block font_14 fw_ar_reg">{CURRENCY_FORMAT(Number(BUNDLE_SUB_TOTAL()) + Number(MEAL_SUB_TOTAL()))}</span>
                                                            </li>
                                                        }
                                                        {
                                                            // Start coupon list
                                                            (this.state.coupons.length <= 0) ? "" :
                                                                this.state.coupons.map(function (coupon, key) {

                                                                    if (coupon.free_shipping == 1 && coupon.coupon_type == "product") {
                                                                        this.setState({
                                                                            freeShipping: 1,
                                                                            cartShippingCost: 0,
                                                                        })
                                                                    } else if (coupon.free_shipping == 1 && coupon.coupon_type == "meal") {
                                                                        this.setState({
                                                                            mealShippingCostStatus: false,
                                                                        })
                                                                    } else if (coupon.free_shipping == 1 && coupon.coupon_type == "any") {
                                                                        this.setState({
                                                                            freeShipping: 1,
                                                                            cartShippingCost: 0,
                                                                            mealShippingCostStatus: false
                                                                        });
                                                                    }

                                                                    return (
                                                                        <Fragment key={key}>
                                                                            <li className="d-flex align-items-center flex-wrap mb_7">
                                                                                <span className="total_span let_spa_2 d-block font_14 fw_ar_reg">COUPON: {coupon.coupon_code}</span>
                                                                                <span className="price_span let_spa_2 text-right d-block font_14 fw_ar_reg">{CURRENCY_FORMAT(coupon.discount_amount)}</span>
                                                                                <a onClick={(e) => this.deleteCoupon(e, key)} href="#" className='red_text font_14 w-100 d-flex fw_ar_reg'>Delete</a>
                                                                            </li>
                                                                        </Fragment>
                                                                    )
                                                                }.bind(this))
                                                            // End coupon list
                                                        }
                                                        {
                                                            // (this.state.items.length <= 0 || this.state.bundle.length <= 0) ? ''
                                                            //     :
                                                                <Fragment>
                                                                    {
                                                                        // Start cart shipping

                                                                        (this.state.freeShipping == 0) ?
                                                                       
                                                                            <Fragment>
                                                                                
                                                                                {
                                                                                    
                                                                                    // (this.state.shippingMethods.length <= 0) ? null :
                                                                                    
                                                                                        this.state.shippingMethods.map(function (shipping, key) {
                                                                                            if (shipping.allow_for_coupon == 0 && ((this.state.shipping_method_id == shipping.id || GET_STORAGE("cartMethodId") == shipping.id))) {
                                                                                                return (
                                                                                                    <Fragment key={key}>
                                                                                                        <li className="d-flex align-items-center flex-wrap mb_7">
                                                                                                            <span className="total_span let_spa_2 d-block font_14 fw_ar_reg">Shipping Cost</span>
                                                                                                            <span className="price_span let_spa_2 text-right d-block font_14 fw_ar_reg">{CURRENCY_FORMAT(shipping.cost)}</span>
                                                                                                        </li>
                                                                                                    </Fragment>
                                                                                                )
                                                                                            }
                                                                                        }.bind(this))
                                                                                }
                                                                            </Fragment>
                                                                            :
                                                                            <Fragment>
                                                                                {
                                                                                    (this.state.shippingMethods.length <= 0) ? null :
                                                                                        this.state.shippingMethods.map(function (shipping, key) {
                                                                                            if (GET_STORAGE("cartMethodId") == 1) {
                                                                                                this.setState({
                                                                                                    cartShippingCost: shipping.cost
                                                                                                });
                                                                                            } else {
                                                                                                this.setState({
                                                                                                    cartShippingCost: 0
                                                                                                });
                                                                                            }
                                                                                            if (( GET_STORAGE("cartMethodId") == shipping.id)) {
                                                                                                // SET_STORAGE("cartMethodId", shipping.id);
                                                                                                return (
                                                                                                    <Fragment key={key}>
                                                                                                        <li className="d-flex align-items-center flex-wrap mb_7">
                                                                                                            <span className="total_span let_spa_2 d-block font_14 fw_ar_reg">Shipping Cost</span>
                                                                                                            <span className="price_span let_spa_2 text-right d-block font_14 fw_ar_reg">{CURRENCY_FORMAT(this.state.cartShippingCost)}</span>
                                                                                                        </li>
                                                                                                    </Fragment>
                                                                                                )
                                                                                            }
                                                                                        }.bind(this))
                                                                                }
                                                                            </Fragment>
                                                                        // End cart shipping
                                                                    }
                                                                </Fragment>
                                                        }
                                                        {/* Start meal shipping cost for total */}
                                                        {
                                                            (MEAL_MENU_PUBLIC && this.state.meals != null && this.state.meals != '' && this.state.meals.items.length > 0) ?
                                                                <Fragment>
                                                                    <li className="d-flex align-items-center flex-wrap mb_7">
                                                                        <span className="total_span let_spa_2 d-block font_14 fw_ar_reg">Meal Shipping Cost</span>
                                                                        <span className="price_span let_spa_2 text-right d-block font_14 fw_ar_reg"> {
                                                                            (this.state.meals.shipping_cost > 0 && this.state.mealShippingCostStatus == true) ?
                                                                                CURRENCY_FORMAT(this.state.meals.shipping_cost)
                                                                                : "Free Shipping"
                                                                        }</span>
                                                                    </li>
                                                                </Fragment>
                                                                : ''
                                                        }
                                                        {/* End meal shipping cost for total */}
                                                        {
                                                            (this.state.taxStatus == 0) ? ''
                                                                :
                                                                <Fragment>
                                                                    <li className="d-flex align-items-center flex-wrap mb_7">
                                                                        <span className="total_span let_spa_2 d-block font_14 fw_ar_reg">Tax</span>
                                                                        <span className="price_span let_spa_2 text-right d-block font_14 fw_ar_reg"> {CURRENCY_FORMAT(Number(this.state.tax_amount) + Number(this.state.meal_tax_amount))}</span>
                                                                    </li>
                                                                </Fragment>
                                                        }
                                                        <li className="d-flex align-items-center flex-wrap mb_7">
                                                            <span className="total_span let_spa_2 d-block font_14 fw_ar_reg">Total</span>
                                                            <span className="price_span let_spa_2 text-right d-block font_14 fw_ar_reg"> {CURRENCY_FORMAT(CART_TOTAL_CURRENCY_FORMAT(this.state.cartTotal))}</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div class="w-100"><button type="submit" name="" value="" class="w-100 justify-content-center d-flex cont_ship_btn shop_more take_quiz_btn font_16 text_white fw_ar_reg">{place_order_loading ? "Please Wait..." : "Continue To Shipping"}</button></div>
                                                {/* <div class="w-100"><a  href="/checkout-shipping" class="w-100 justify-content-center d-flex cont_ship_btn shop_more take_quiz_btn font_16 text_white fw_ar_reg">Continue To Shipping</a></div> */}
                                                {/* End cart view */}

                                                {/* Start payment method */}

                                                {/* warning modal start  */}
                                                <div className="modal fade" id="warningModal" tabIndex="-1" role="dialog" aria-labelledby="warningModal" aria-hidden="true">
                                                    <div className="modal-dialog modal-lg" role="document" style={{ marginTop: '5%' }}>
                                                        <div className="modal-content warningModalContent">
                                                            <div className="modal-header cus-modal-header">
                                                                <h3>Warnings</h3>
                                                                <button type="button" className="close pl-warning-close" data-dismiss="modal" aria-label="Close">
                                                                    <span aria-hidden="true">&times;</span>
                                                                </button>
                                                            </div>
                                                            <div className="modal-body montserrat pl-warning-body">
                                                                <div className="mt-2 warning-block-cards" id="warningModalContent"></div>
                                                                <div className="d-flex align-items-center justify-content-center py-3">
                                                                    <div className="d-flex flex-column">
                                                                        <button className="btn mr-4 btn-close" onClick={this.warningModalCancelHandel} type="button" >Cancel</button>
                                                                        <span className="warning-cancel-help-text">Remove item(s) from the cart</span>
                                                                    </div>
                                                                    <div className="d-flex flex-column">
                                                                        <button onClick={this.addWarning} className="btn btn-confirm" data-dismiss="modal">OK Proceed</button>
                                                                        <sapn className="warning-cancel-help-text"></sapn>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* warning modal end  */}

                                                {/* canadaRestrictedProductModal start  */}

                                                <div className="modal fade" id="canadaRestrictedProductModal" tabIndex="-1" role="dialog" aria-labelledby="canadaRestrictedProductModal" aria-hidden="true">
                                                    <div className="modal-dialog" role="document">
                                                        <div className="modal-content">
                                                            <div className="modal-header cus-modal-header">
                                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                                    <span aria-hidden="true">&times;</span>
                                                                </button>
                                                            </div>
                                                            <div className="modal-body montserrat">
                                                                <div className="text-danger cus-modal-title">Alert!</div>
                                                                <p className="modal-body-content" style={{ fontWeight: 'bold' }} id="canadaRestrictedProductModalBody"></p>
                                                            </div>
                                                            <div className="modal-footer" style={{ justifyContent: "center" }}>
                                                                <a href="/cart" className="">Back to Cart</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* canadaRestrictedProductModal end  */}

                                                {/* Product stockout check modal start  */}

                                                <div className="modal fade" id="stockoutErrorModal" tabIndex="-1" role="dialog" aria-labelledby="stockoutErrorModal" aria-hidden="true">
                                                    <div className="modal-dialog" role="document">
                                                        <div className="modal-content">
                                                            <div className="modal-header cus-modal-header">
                                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                                    <span aria-hidden="true">&times;</span>
                                                                </button>
                                                            </div>
                                                            <div className="modal-body montserrat">
                                                                <div className="text-danger cus-modal-title">Alert!</div>
                                                                <p className="modal-body-content">{Parser(this.state.stockoutErrorMessage)}</p>
                                                                <NavLink to="#" onClick={this.backToCart} className="wc-forward">Back to Cart</NavLink>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* Product stock check modal end  */}

                                            </form>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Fragment>
                }
            </Fragment>
        );
    }
}

CheckOut.propTypes = {
    checkoutRequest: PropTypes.func.isRequired,
    setUserLogin: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    meals: PropTypes.array.isRequired
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        meals: state.meals
    };
}

export default connect(mapStateToProps, { checkoutRequest, setUserLogin })(CheckOut);
