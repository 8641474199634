import React, { Fragment, PureComponent } from 'react';
import { NavLink } from "react-router-dom";
import ReactImageFallback from "react-image-fallback";
import { CURRENCY_FORMAT } from '../../Constants/AppConstants';
import Parser from 'html-react-parser';
import rightArrow from './../../Assets/images/public_images/arrowright.svg'

class SingleProduct extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {

        const product = this.props.product;
        const decodeHtmlEntities = (text) => {
            const element = document.createElement('div');
            if (text) {
                element.innerHTML = text;
                return element.textContent;
            }
            return '';
        };
    
        const stripTagsAndTruncate = (input, maxLength) => {
            const strippedString = input.replace(/<\/?[^>]+(>|$)/g, "");
            const decodedString = decodeHtmlEntities(strippedString);
            if (decodedString.length > maxLength) {
                return decodedString.substring(0, maxLength) + '...';
            }
            return decodedString;
        };
        return (
            <Fragment>
                <NavLink className="text-center d-flex w-100" to={`/product/${product.slug}`}>
                    <div className="refer_produc_wrapper public_pro_wrapper">
                        <div className="refer-img-wrapper text-center">
                            {/* <NavLink className="text-center refer-img-thumb mx-auto" to={`/product/${product.slug}`}> */}
                                {/* <ReactImageFallback
                                    src={(this.props.type == 'bundle') ? product.main_image : product.list_image}
                                    fallbackImage={require('../../Assets/images/preloader.gif')}
                                    initialImage={require('../../Assets/images/preloader.gif')}
                                    alt={product.hasOwnProperty('title') ? product.title : ''}
                                    className="img-fluid" /> */}
                                    <img
                                    src={(this.props.type == 'bundle') ? product.main_image : product.list_image}
                                    fallbackImage={require('../../Assets/images/preloader.gif')}
                                    initialImage={require('../../Assets/images/preloader.gif')}
                                    alt={product.hasOwnProperty('title') ? product.title : ''}
                                    className="img-fluid" />
                            {/* </NavLink> */}
                            {/* <div className="product_quick_view">
                            <div className="refer_product_select_option_wrapper">
                                <NavLink to={`/product/${product.slug}`} className="refer_product_select_option">Select Options</NavLink>
                            </div>
                            <div className="refer_product_quickview_wrapper">
                                <a onClick={()=>product.quickView(product.product_id)} href="#" className="refer_product_quickview" data-toggle="modal" data-target="#productQucikView">Quickview</a>
                            </div>
                        </div> */}
                        </div>
                        <div className="refer_product_short_desc d-flex align-items-center justify-content-between">
                            <div className="refer_product_left d-flex flex-column align-items-start">
                                <h3 className="m-0 font_20 fw_el_bold text_black text-left">{product.hasOwnProperty('title') ? product.title : ''}</h3>
                                <span className="font_16 text_black fw_ar_reg text-left">{product.hasOwnProperty('start_price') ? CURRENCY_FORMAT(product.start_price) : ''}</span>
                                <h3 className="m-0 font_20 fw_el_bold text_black text-left">{product.hasOwnProperty('short_description') ? stripTagsAndTruncate(product.short_description) : ''}</h3>
                            </div>
                            <div className="refer_product_right">
                                <img src={rightArrow} alt="right_arrow" />
                            </div>
                        </div>
                    </div>
                </NavLink>
            </Fragment>
        );
    }
}

export default SingleProduct;
