import React, { PureComponent, Fragment } from 'react';
import Parser from 'html-react-parser';
import classnames from 'classnames';
import AlertWrapper from '../Common/AlertWrapper';
import AlertWrapperSuccess from '../Common/AlertWrapperSuccess';
import { AJAX_PUBLIC_REQUEST, DESTROY_CART } from '../../Constants/AppConstants';
import history from '../../history';
// import validateContactForm from '../../Validations/validateContactForm';
// import "../../Assets/css/captcha.css";
// import EmailIcon from '../../Assets/images/customer_images/email_icon.svg'
// import PhoneIcon from '../../Assets/images/customer_images/phone_icon.svg'
// import LocationIcon from '../../Assets/images/refer_images/location_black_icon.svg'

class GsxRedirect extends PureComponent {

    constructor(props) {
        super(props)
        this.state = {
            loading: true,
        }
        document.title = "GSX Redirect - Prestige Labs";
    }

    componentDidMount() {
        DESTROY_CART();
        // this.timeOut(100);
        history.push('/');
        document.querySelector("body").scrollIntoView();
    }

    // timeOut = (timedata) => {
    //     setTimeout(function () {
    //         history.push('/');
    //     }.bind(this), timedata);
    // }

    render() {
        return (
            <Fragment>
                {
                    (this.state.loading) ?
                        <div className="loading container full_page_loader"></div>
                        :
                        <Fragment>
                            <div className="login_container contact_container">
                                <div className="container">
                                    <h3 className="font_26 text_black fw_ar_bold mb_13">Global Shopex Redirecting</h3>
                                </div>
                            </div>
                        </Fragment>
                }
            </Fragment>
        );
    }
}

export default GsxRedirect;