import React, { Fragment, PureComponent } from 'react';
import { NavLink } from "react-router-dom";
import { connect } from 'react-redux';
import { AJAX_PUBLIC_REQUEST, AJAX_REQUEST, CURRENCY_FORMAT, SET_STORAGE, GET_STORAGE, klaviyoViewedProduct, klaviyoAddedToCart, USER, IS_AUTHENTICATED, BUNDLE_CART_COUNT } from '../../Constants/AppConstants';
import Parser from 'html-react-parser';
import history from "../../history";
import $ from 'jquery';
import { Loader } from 'klaviyo-loader';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import OutOfStockButton from './OutOfStockButton';
import 'react-photoswipe/lib/photoswipe.css';
import { PhotoSwipe } from 'react-photoswipe';
import { Row, Col } from 'react-bootstrap';
import HomeIcon from './../../Assets/images/refer_images/home_icon.svg'
import SubDropdown from './../../Assets/images/affiliate_imges/sub_dropdown.svg'

let item_data = [];

let img = '';
let lens = '';
let result = '';
let cx = '';
let cy = '';

function moveLens(e) {
    var pos, x, y;
    e.preventDefault();
    pos = getCursorPos(e);
    x = pos.x - (lens.offsetWidth / 2);
    y = pos.y - (lens.offsetHeight / 2);
    if (x > img.width - lens.offsetWidth) { x = img.width - lens.offsetWidth; }
    if (x < 0) { x = 0; }
    if (y > img.height - lens.offsetHeight) { y = img.height - lens.offsetHeight; }
    if (y < 0) { y = 0; }
    lens.style.left = x + "px";
    lens.style.top = y + "px";
    document.getElementById('mkn-img-zoom-result').style.backgroundPosition = "-" + (x * cx) + "px -" + (y * cy) + "px";
}

function getCursorPos(e) {
    var a, x = 0, y = 0;
    e = e || window.event;
    a = img.getBoundingClientRect();
    x = e.pageX - a.left;
    y = e.pageY - a.top;
    x = x - window.pageXOffset;
    y = y - window.pageYOffset;
    return { x: x, y: y };
}

// $(document).on({
//     mousemove: function (e) {
//         const imgurl = $(this).children('img').attr('largesrc');
//         lens = this.childNodes[0];
//         img = this.childNodes[1];
//         result = document.getElementById('mkn-img-zoom-result');
//         cx = result.offsetWidth / lens.offsetWidth;
//         cy = result.offsetHeight / lens.offsetHeight;
//         result.style.backgroundImage = "url('" + imgurl + "')";
//         result.style.backgroundSize = (img.width * cx) + "px " + (img.height * cy) + "px";
//         moveLens(e);
//     }
// }, '.mkn-img-container');

// $(document).on({ mouseover: function () { $('#mkn-img-zoom-result').show(); } }, '.mkn-img-container');
// $(document).on({ mouseout: function () { $('#mkn-img-zoom-result').hide(); } }, '.mkn-img-container');

class ProductDrtails extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            addToCart: "Add to Cart",
            product_id: this.props.match.params.slug,
            productDetails: {},
            subsDisplay: true,
            alertMsg: false,
            items: [],
            options: {},
            isOpen: false,
            flavorId: '',
            monthId: '',
            variationId: '',
            isAuthenticated: IS_AUTHENTICATED(),
            user: {},
        }
        document.title = "Product Details -Prestige Labs";
    }

    componentDidMount() {

        document.querySelector("body").scrollIntoView();
        const data = {
            product_id: this.state.product_id,
        }

        AJAX_PUBLIC_REQUEST("POST", "product/getDetails", data).then(results => {
            if (parseInt(results.response.code) === 1000) {
                const product = results.response.data;
                document.title = product.title + " - Prestige Labs";
                product.images.map(function (image, key) {
                    item_data.push({
                        src: image.large_image,
                        w: 1200,
                        h: 900,
                        title: product.title
                    });
                }.bind(this));

                this.setState({
                    productDetails: product,
                    subscriptionFlavors: product.flavors,
                    items: item_data,
                    loading: false,
                    subscription: 'no',
                });

                if (product.flavors) {
                    this.setState({
                        variationId: product.first_month + "_" + product.flavors[0].id,
                        monthId: product.first_month,
                        flavorId: product.flavors[0].id
                    })
                } else {
                    this.setState({
                        variationId: product.first_month,
                        monthId: product.first_month
                    })
                }

                setTimeout(() => {
                    this.variation(this.state.variationId);
                    if (this.state.productDetails.flavors) {
                        this.activeFlavors(this.state.productDetails, this.state.monthId);
                    }
                }, 100)

            } else if (parseInt(results.response.code) === 4004) {
                history.push("/");
            } else {
                this.setState({
                    error: Parser("<p className='text-danger'>" + results.response.message + "</p>"),
                    loading: false,
                })
            }
        });

        if (this.state.isAuthenticated == true) {
            this.setState({
                user: JSON.parse(GET_STORAGE(USER))
            })
        }
        window.addEventListener('popstate', this.handlePopState);
    }

    handlePopState = () => {
        // Reloading the page when the back button is clicked
        window.location.reload();
    };

    variation = (variationKey, changeMonth) => {
        const product = this.state.productDetails;
        const variation = product.variations[variationKey];
        if (variation) {

            let regularPriceDisplay = false;
            let regular_price = 0;
            let sale_price = 0;
            if (parseFloat(variation.sale_price) > 0) {
                regularPriceDisplay = true;
                regular_price = variation.regular_price;
                sale_price = variation.sale_price;
            } else {
                regular_price = variation.regular_price;
                sale_price = variation.regular_price;
            }

            let weight_lb = 0.00;
            if ((variation.dimensions) !== null) {
                weight_lb = variation.dimensions.weight_lb;
            } else {
                weight_lb = 0.00;
            }

            this.setState({
                productId: product.product_id,
                productName: product.title,
                productSKU: variation.sku,
                productDescription: product.short_description,
                productIsBundle: product.is_bundle,
                productWeightInlb: weight_lb,
                productSlug: product.slug,
                image: product.thumb_image,
                variationId: variation.variation_id,
                variationName: variation.variation_name,
                regularPriceDisplay: regularPriceDisplay,
                regularPrice: regular_price,
                salePrice: sale_price,
                subscription: changeMonth ? 'no' : this.state.subscription,
                subscriptionMsg: 'Every 1 Month(s)',
                discountPrice: ((sale_price - (sale_price * product.subscription_save_percentage) / 100)),
                stockStatus: variation.stock_status,
                inStock: parseInt(variation.quantity),
                warehouse: variation.warehouse,
                quantity: 1,
            })

            klaviyoViewedProduct({
                productId: product.product_id,
                productName: product.title,
                productSKU: variation.sku,
                image: product.thumb_image,
                url: window.location.href,
                regularPrice: regular_price,
                salePrice: sale_price,
            });

        } else {
            this.setState({
                regularPriceDisplay: false,
                regularPrice: 0,
                salePrice: 0,
            })
        }
    }
    changeMonth = (e) => {
        const month_id = e.target.getAttribute('month_id');
        const changeMonth = true;
        this.setState({
            monthId: month_id,
            subscription: 'no',
        })
        // if (this.state.productDetails.flavors) {
        //     this.variation(month_id + "_" + this.state.flavorId, changeMonth)
        // } else {
        //     this.variation(month_id, changeMonth)
        // }
        if (month_id == this.state.productDetails.first_month) {
            this.setState({ subsDisplay: true });
        } else {
            this.setState({ subsDisplay: false });
        }
        if (this.state.productDetails.flavors != undefined) {
            this.filterFlavors('no');
            this.activeFlavors(this.state.productDetails, month_id, changeMonth);
        } else {
            this.variation(month_id, changeMonth)
        }
    }

    activeFlavors = (product, monthId, changeMonth) => {
        const  monthIdAsInt = parseInt(monthId)
        const variationsForMonth = Object.values(product.variations)
            .filter(variation => variation.month === monthIdAsInt);
        const activeFlavorIds = variationsForMonth.map(variation => variation.flavor_id);
        const updatedFlavors = this.state.subscriptionFlavors.filter(flavor => activeFlavorIds.includes(flavor.id));
        let defaultFlavor = null;
        if (updatedFlavors.length > 0) {
            defaultFlavor = updatedFlavors.find(flavor => flavor.id === this.state.flavorId) || updatedFlavors[0];
        }
        this.setState({
            productDetails: {
                ...this.state.productDetails,
                flavors: updatedFlavors,
                defaultFlavor: defaultFlavor,
            }
        }, () => {
            if (defaultFlavor) {
                this.setState({ activeFlavors: updatedFlavors })
                this.setState({ flavorId: defaultFlavor.id });
            }
        });
        if (defaultFlavor) {
            let variationKey = monthId + "_" + defaultFlavor.id;
            this.variation(variationKey, changeMonth);
        }

    };

    changeFlavor = (e) => {
        const flavor_id = e.target.getAttribute('flavor_id');
        this.setState({ flavorId: flavor_id })
        let variationKey = this.state.monthId + "_" + flavor_id;
        this.variation(variationKey);
    }

    addToCart = (e) => {

        let cart = [];
        let data = [];

        console.log(BUNDLE_CART_COUNT(), 'BUNDLE_CART_COUNT');
        if(BUNDLE_CART_COUNT() != 0){
            $(`#${'alertModel'}`).modal({
                backdrop: 'static',
                keyboard: false,
            });
        } else{
            if (!GET_STORAGE("cart")) {
                SET_STORAGE("cart", JSON.stringify(cart));
            }
            cart = JSON.parse(GET_STORAGE("cart"));
            let newItem = {
                cart_product_id: e.target.getAttribute('cart_product_id'),
                cart_product_name: e.target.getAttribute('cart_product_name'),
                cart_product_sku: e.target.getAttribute('cart_product_sku'),
                cart_product_description: e.target.getAttribute('cart_product_description'),
                cart_product_isbundle: e.target.getAttribute('cart_product_isbundle'),
                cart_product_weight_lb: e.target.getAttribute('cart_product_weight_lb'),
                cart_product_slug: e.target.getAttribute('cart_product_slug'),
                cart_image: e.target.getAttribute('cart_image'),
                cart_variation_id: e.target.getAttribute('cart_variation_id'),
                cart_variation_name: e.target.getAttribute('cart_variation_name'),
                cart_sale_price: e.target.getAttribute('cart_sale_price'),
                subscription: e.target.getAttribute('subscription'),
                cart_subscription_msg: e.target.getAttribute('cart_subscription_msg'),
                cart_discount_price: e.target.getAttribute('cart_discount_price'),
                quantity: parseInt(e.target.getAttribute('quantity')),
                in_stock: parseInt(e.target.getAttribute('in_stock')),
                warehouse: e.target.getAttribute('warehouse')
            }

            const addToCartDb = this.addToCartDb;

            if (cart.length > 0) {
                cart.forEach(function (item, key) {
                    if ((item.cart_variation_id == e.target.getAttribute('cart_variation_id')) && (item.subscription.toLowerCase() == e.target.getAttribute('subscription').toLowerCase())) {
                        if (parseInt(item.quantity) >= parseInt(e.target.getAttribute('in_stock'))) {
                            alert("Out Of Stock") // Check product quantity
                        } else {
                            item.quantity = Number(item.quantity) + Number(newItem.quantity);
                        }
                        data.push(item);
                        addToCartDb(item); // Cart add to db
                        newItem = null;
                    } else {
                        data.push(item);
                    }
                });
                if (newItem != null) {
                    data.push(newItem);
                    addToCartDb(newItem); // Cart add to db
                }
            } else {
                data.push(newItem);
                addToCartDb(newItem); // Cart add to db
            }

            SET_STORAGE("cart", JSON.stringify(data));
            this.addToCartLabelChange();
            this.props.addToCart();
            document.querySelector("body").scrollIntoView();

            klaviyoAddedToCart(e.target.getAttribute('cart_variation_id'));
        }
    }

    handleCart = () => {
        history.push("/cart");
    }

    addToCartDb = (data) => {
        AJAX_REQUEST("POST", "cart/saveItem", data).then(results => {
            if (parseInt(results.response.code) !== 1000) {
                this.setState({ error: results.response.message });
                this.props.updateError(results.response.message);
            }
        });
    }

    addToCartLabelChange = (e) => {
        this.setState({
            alertMsg: true,
            addToCart: "Adding..."
        })

        setTimeout(function () {
            this.setState({
                addToCart: "Thank You"
            })
        }.bind(this), 1000)

        setTimeout(function () {
            this.setState({
                addToCart: "Add More ..."
            })
        }.bind(this), 2000)

        setTimeout(function () {
            this.setState({
                alertMsg: false,
            })
        }.bind(this), 5000)
    }

    isSubscription = (e) => {
        this.setState({ subscription: "yes" }, () => {
            if (this.state.productDetails.flavors != undefined) {
                this.filterFlavors(this.state.subscription);
            }
        });
    }

    filterFlavors = (subscription) => {
        if (subscription == 'yes') {
            const variationsWithSubscriptionStatus0 = Object.values(this.state.productDetails.variations)
                .filter(variation => variation.subscription_status === "0");
            const flavorIdsToRemove = variationsWithSubscriptionStatus0.map(variation => variation.flavor_id);
            const updatedFlavors = this.state.productDetails.flavors.filter(flavor => !flavorIdsToRemove.includes(flavor.id));
            this.setState({ productDetails: { ...this.state.productDetails, flavors: updatedFlavors } });
            let defaultFlavor = null;

            if (updatedFlavors.length > 0) {
                defaultFlavor = updatedFlavors.find(flavor => flavor.id === this.state.flavorId) || updatedFlavors[0];
            }

            this.setState({
                productDetails: {
                    ...this.state.productDetails,
                    flavors: updatedFlavors,
                    defaultFlavor: defaultFlavor,
                }
            }, () => {
                if (defaultFlavor) {
                    this.setState({ flavorId: defaultFlavor.id });
                }
            });
            if (defaultFlavor) {
                let variationKey = this.state.monthId + "_" + defaultFlavor.id;
                this.variation(variationKey);
            }
        } else {
            this.setState({
                productDetails: {
                    ...this.state.productDetails,
                    flavors: this.state.activeFlavors,
                    defaultFlavor: null,
                }
            });
        }
    }

    noSubscription = (e) => {
        this.setState({ subscription: "no" }, () => {
            if (this.state.productDetails.flavors != undefined) {
                this.filterFlavors(this.state.subscription);
            }
        });
    }

    quantityIncrement = (e) => {
        this.setState({ quantity: Number(this.state.quantity) + 1 })
    }

    quantityDecrement = (e) => {
        this.setState({ quantity: Number(this.state.quantity) - 1 })
    }

    handleClose = () => {
        this.setState({ isOpen: false });
        window.location.reload();
    }

    openGallery = () => {
        this.setState({ isOpen: true });
    }

    render() {
        const script = document.createElement('script');
        script.src = "https://cdn1.stamped.io/files/widget.min.js";
        script.async = true;
        script.onload = () => {
            if (window.StampedFn) {
                window.StampedFn.init({ apiKey: '73049c0d-2470-41cc-b5e8-e0071a09c965', sId: '331813' });
                window.StampedFn.reloadUGC();
            }
        };
        document.body.appendChild(script);

        const product = this.state.productDetails;

        return (
            <Fragment>
                {
                    (this.state.loading) ?
                        <div className="loading container full_page_loader"></div>
                        :
                        <Fragment>
                            <div className="site-wrapper pro_det_div">
                                <div className="container-fluid p-0">
                                    {(product.status == 1) ?
                                        <Row className='m-0'>
                                            <Col md={6} className="pro_left_blk bg_white">
                                                <div className="d-flex mb_44 align-items-center justify-content-between">
                                                    <nav aria-label="breadcrumb" className=''>
                                                        <ol className="breadcrumb cus_breadcrumb m-0 pro_breadcrumb d-flex align-items-center">
                                                            <li className="breadcrumb-item d-flex"><NavLink to="/" 
                                                                onClick={(e) => {
                                                                e.preventDefault();
                                                                window.location.href = "/";
                                                            }}><img src={HomeIcon} alt="home_icon" /></NavLink></li>
                                                            <li className="breadcrumb-item d-flex">
                                                                {
                                                                    (product.is_bundle == 0) ?
                                                                    <NavLink 
                                                                        to="/best-sellers" 
                                                                        className="font_16 text_gray fw_ar_reg d-flex"
                                                                        onClick={(e) => {
                                                                            e.preventDefault(); // Prevent the default single-page application behavior
                                                                            window.location.href = "/best-sellers"; // Navigate to the Best Sellers page and reload
                                                                        }}
                                                                    >
                                                                        &gt; Best Sellers
                                                                    </NavLink>
                                                                    :
                                                                    <NavLink 
                                                                        to="/bundle-product" 
                                                                        className="font_16 text_gray fw_ar_reg d-flex"
                                                                        onClick={(e) => {
                                                                            e.preventDefault(); // Prevent the default single-page application behavior
                                                                            window.location.href = "/bundle-product"; // Navigate to the Bundle Product page and reload
                                                                        }}
                                                                    >
                                                                        &gt; Bundle Product
                                                                    </NavLink>
                                                                }
                                                            </li>
                                                            <li className="breadcrumb-item d-flex active font_16 text_gray fw_ar_reg" aria-current="page">&gt; {product.hasOwnProperty('title') ? product.title : ''}</li>
                                                        </ol>
                                                    </nav>
                                                    {/* <img src={ShareIcon} alt="share icon" /> */}
                                                </div>
                                                <PhotoSwipe isOpen={this.state.isOpen} items={this.state.items} options={this.state.options} onClose={this.handleClose} />
                                                <div className='pro_img_carousel'>
                                                <Carousel autoPlay onClickItem={this.openGallery}>
                                                    {(!product.hasOwnProperty('images')) ? this.state.error :
                                                        product.images.map(function (image, key) {
                                                            return (
                                                                <div key={key} className="mkn-img-container">
                                                                    {/* <div className="mkn-img-zoom-lens"></div> */}
                                                                    <img src={image.main_image} largesrc={image.large_image} className="img-responsive" alt={`Image ${key}`} />
                                                                </div>
                                                            );
                                                        }.bind(this))
                                                    }
                                                    {(!product.hasOwnProperty('video_links')) ? "" : 
                                                        product.video_links !== "[]" ?
                                                        product.video_links.map(function (link, key) {
                                                            return (
                                                            <div key={key} className="hide_image">
                                                                <iframe className='carousel_iframe' src={link.source_link} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                                                <img src={require('./../../Assets/images/video_svg.svg')} />
                                                            </div>   
                                                            );
                                                        }.bind(this)) : ""
                                                    }
                                                    
                                                    {/* <div className="mkn-video-container">
                                                        <video width="640" height="360" controls>
                                                            <source src="https://www.youtube.com/embed/peYoYJxgFD4?si=AFBrjokJUudu7u5o" />
                                                        </video>
                                                    </div> */}
                                                </Carousel>
                                                </div>

                                            </Col>
                                            <Col md={6} className="pro_rgt_blk">
                                                <div id="mkn-img-zoom-result" className="mkn-img-zoom-result"></div>
                                                <h1 className="font_41 m-0 text_black fw_el_semi let_spa_2">{product.hasOwnProperty('title') ? product.title : ''}</h1>
                                                {/* <h2 className="font_20 mb_21 text_black fw_ar_reg">Psdsrestige Labs</h2> */}
                                                <div className="d-flex align-items-center justify-content-between mb_5">
                                                    <div className="pro_pri_blk d-flex align-items-center flex-wrap">
                                                        <h3 className="font_24 m-0 text_black fw_ar_bold let_spa_2"> {(this.state.salePrice) ? CURRENCY_FORMAT(this.state.salePrice) : ''}</h3>
                                                        <h4 className='ml_13 font_16 mb_0 line_through text_black let_spa_2 fw_ar_reg'>{(this.state.regularPrice != 0.00) ? CURRENCY_FORMAT(this.state.regularPrice) : ''}</h4>
                                                    </div>
                                                </div>
                                                <div class="mb_5">
                                                    <h3 className="font_20 m-0 text_black fw_ar_reg let_spa_2">{product.hasOwnProperty('short_description') ? Parser(product.short_description) : ''}</h3>
                                                </div>
                                                <ul>
                                                    <li className='d-flex align-items-center mb_15'>
                                                        <span className='d-block font_16 text_black fw_ar_reg'>Availability  </span>
                                                        {
                                                            (this.state.inStock > 0) ?
                                                                <span className="d-block font_14 pl_5 text_green fw_ar_reg">In stock</span>
                                                                :
                                                                <span className="d-block font_14  pl_5 red_text fw_ar_reg">Stock Out</span>
                                                        }
                                                    </li>
                                                    {/* <li className='d-flex align-items-center mb_15'>
                                                        <span className='d-block font_16 text_black fw_ar_reg'>Vendor </span>
                                                        <span className='d-block font_16 pl_5 text_black fw_ar_bold'>Prestige Labs</span>
                                                    </li> */}
                                                </ul>


                                                {
                                                    (!product.hasOwnProperty('flavors')) ? '' :
                                                        <Fragment>
                                                            <div className="choose_flavour mb_16">
                                                                <h3 className="font_16 mb_7 choose_text fw_ar_reg">Flavors</h3>
                                                                {(product.flavors.length > 0) ?
                                                                    <div className="swatch_block">
                                                                        {
                                                                            product.flavors.map(function (flavor, key) {
                                                                                return (
                                                                                    <Fragment key={Math.random()}>
                                                                                        <div className='position-relative'>
                                                                                            <input type="radio" name="attribute_flavors" defaultValue={flavor.hasOwnProperty('id') ? flavor.id : ''} checked={((this.state.flavorId == flavor.id) || (this.state.defaultFlavor && this.state.defaultFlavor.id === flavor.id)) ? "checked" : ""} readOnly />
                                                                                            <label onClick={this.changeFlavor} flavor_id={flavor.hasOwnProperty('id') ? flavor.id : ''} htmlFor={flavor.hasOwnProperty('value') ? flavor.value : ''}>{flavor.hasOwnProperty('value') ? flavor.value : ''}</label>
                                                                                        </div>
                                                                                    </Fragment>
                                                                                )
                                                                            }.bind(this))
                                                                        }
                                                                    </div>
                                                                    : <h3 className="font_16 text_black fw_ar_bold mb_8">Currently No Flavors Active for Subscription!</h3>
                                                                }
                                                            </div>

                                                        </Fragment>
                                                }
                                                <div className="choose_flavour mb_16">
                                                    <h3 className="font_16 mb_7 choose_text fw_ar_reg">Month</h3>
                                                    <div className="swatch_block">
                                                        <div className='position-relative'>
                                                            <input type="radio" name="attribute_months" defaultValue={product.hasOwnProperty('first_month') ? product.first_month : ''} checked={(this.state.monthId == product.first_month) ? "checked" : ""} readOnly />
                                                            <label onClick={this.changeMonth} month_id={product.hasOwnProperty('first_month') ? product.first_month : ''} htmlFor={product.hasOwnProperty('title') ? product.title : ''}>1 Month</label>
                                                        </div>
                                                        {
                                                            (!product.hasOwnProperty('months')) ? '' :
                                                                <Fragment>
                                                                    {
                                                                        product.months.slice().sort((a, b) => parseInt(a.id) - parseInt(b.id)) .map(function (month, key) {
                                                                            return (
                                                                                <Fragment key={Math.random()}>
                                                                                    <div className='position-relative'>
                                                                                        <input type="radio" name="attribute_months" defaultValue={month.hasOwnProperty('id') ? month.id : ''} checked={(this.state.monthId == month.id) ? "checked" : ""} readOnly />
                                                                                        <label onClick={this.changeMonth} month_id={month.hasOwnProperty('id') ? month.id : ''} htmlFor={month.hasOwnProperty('value') ? month.value : ''}>{month.hasOwnProperty('value') ? month.value : ''}</label>
                                                                                    </div>
                                                                                </Fragment>
                                                                            )
                                                                        }.bind(this))
                                                                    }
                                                                </Fragment>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="quantity_blk mb_16">
                                                    <h3 className="font_16 choose_text fw_ar_reg mb_7">Quantity</h3>
                                                    <div className="quan_cart_blk d-flex">
                                                        <div className="input-group refer_product_input_group">
                                                            <div className="input-group-prepend">
                                                                <button onClick={this.quantityDecrement} disabled={(this.state.quantity <= 1) ? "disabled" : ""} className="btn btn-sm" id="minus-btn"><i className="fa fa-minus"></i></button>
                                                            </div>
                                                            <input defaultValue={this.state.quantity} type="number" id="qty_input" className="text-center form-control form-control-sm product_quantity_content refer_product_quantity_content" readOnly />
                                                            <div className="input-group-prepend">
                                                                <button onClick={this.quantityIncrement} disabled={(this.state.quantity >= this.state.inStock) ? "disabled" : ""} className="btn btn-sm" id="plus-btn"><i className="fa fa-plus"></i></button>
                                                            </div>
                                                        </div>

                                                        {
                                                            (this.state.inStock <= 0) ?
                                                                <OutOfStockButton />
                                                                :
                                                                <Fragment>
                                                                    {((product.flavors && product.flavors.length > 0) || product.flavors == undefined) ?
                                                                        <input
                                                                            onClick={this.addToCart}
                                                                            cart_product_id={this.state.productId}
                                                                            cart_product_name={this.state.productName}
                                                                            cart_product_sku={this.state.productSKU}
                                                                            cart_product_description={this.state.productDescription}
                                                                            cart_product_isbundle={this.state.productIsBundle}
                                                                            cart_product_weight_lb={this.state.productWeightInlb}
                                                                            cart_product_slug={this.state.productSlug}
                                                                            cart_image={this.state.image}
                                                                            cart_variation_id={this.state.variationId}
                                                                            cart_variation_name={this.state.variationName}
                                                                            cart_sale_price={this.state.salePrice}
                                                                            subscription={this.state.subscription}
                                                                            cart_subscription_msg={this.state.subscriptionMsg}
                                                                            cart_discount_price={this.state.discountPrice}
                                                                            quantity={this.state.quantity}
                                                                            in_stock={this.state.inStock}
                                                                            warehouse={this.state.warehouse}
                                                                            defaultValue={this.state.addToCart} name="addToCart" className="cart_add_product_btn" type="button" />
                                                                        : <Fragment>
                                                                            <input name="addToCart" value="Add To Cart" className="cart_add_product_btn stockout-btn disable" type="button" />
                                                                        </Fragment>
                                                                    }
                                                                </Fragment>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center mb_16">
                                                    <span className='font_16 text_black fw_ar_reg d-block'>Subtotal </span>
                                                    {(this.state.subscription == 'no') ?
                                                        <span className='font_16 text_black fw_ar_bold d-block pl_10'>{CURRENCY_FORMAT(this.state.salePrice * this.state.quantity)}</span>
                                                        : <span className='font_16 text_black fw_ar_bold d-block pl_10'>{CURRENCY_FORMAT(this.state.discountPrice * this.state.quantity)}</span>
                                                    }
                                                </div>
                                                {
                                                    (!this.state.subsDisplay || product.subscription == 'no') ? ''
                                                        :
                                                        <Fragment>
                                                            <h3 className="font_16 text_black fw_ar_bold mb_8">
                                                                Subscribe and Save!
                                                            </h3>
                                                            <label onClick={this.noSubscription} className="w-fitcontent subscri_blk pro_subscri_blk  d-flex align-items-center checkbox_label mb_15 radio_label" htmlFor="purchase_type_simple">
                                                                <div className="sub_lft position-relative">
                                                                    <input id="purchase_type_simple" type="radio" defaultValue="simple" defaultChecked="checked" name="purchase_type" className="" />
                                                                    <span className="checbox"></span>
                                                                </div>
                                                                <div className="sub_rgt pl_16">
                                                                    <div className="d-flex align-items-center">
                                                                        <h3 className="font_16 text_black let_spa_2 fw_ar_reg m-0">Purchase just this product</h3>
                                                                        {/* <img src={SubDropdown} alt="SubDropdown" className="ml_19" /> */}
                                                                    </div>
                                                                </div>
                                                            </label>
                                                            {(!this.state.isAuthenticated) || (this.state.user.user_international_checkout !== 1) ?
                                                                <label onClick={this.isSubscription} className="w-fitcontent subscri_blk pro_subscri_blk d-flex align-items-center checkbox_label mb_0 radio_label" htmlFor="purchase_type_subscription">
                                                                    <div className="sub_lft position-relative">
                                                                        <input id="purchase_type_subscription" defaultValue="subscription" name="purchase_type" className="" type="radio" />
                                                                        <span className="checbox"></span>
                                                                    </div>
                                                                    <div className="sub_rgt pl_16">
                                                                        <div className="d-flex align-items-center">
                                                                            <h3 className="font_16 text_black let_spa_2 fw_ar_reg m-0">Subscribe and Save {product.hasOwnProperty('subscription_save_percentage') ? product.subscription_save_percentage : null} % for 1 month</h3>
                                                                            {/* <img src={SubDropdown} alt="SubDropdown" className="ml_19" /> */}
                                                                        </div>
                                                                        {
                                                                            (this.state.subscription == 'no') ? ''
                                                                                :
                                                                                <div className="d-flex align-items-center mt_5">
                                                                                    <span className="d-block font_14 text_black fw_ar_bold let_spa_2">Order will ship every: 1 Month(s)</span>
                                                                                </div>
                                                                        }
                                                                    </div>
                                                                </label>
                                                                : " "
                                                            }
                                                        </Fragment>
                                                }
                                                <div class="mt_10">
                                                    <h3 className="font_16 m-0 text_black fw_ar_reg let_spa_2">{product.hasOwnProperty('overview') && product.overview !== null ? Parser(product.overview) : ''}</h3>
                                                </div> 
                                            </Col>
                                            <Col md={12} className='pro_dec_blk'>
                                                <div className="pro_det_div_des mb_20 mt_20"> {product.hasOwnProperty('description') ? Parser(product.description) : ''}</div>
                                            </Col>
                                            <Col md={12} className='pro_dec_blk'>
                                                {/* Stamped Reviews  */}
                                                <div id="stamped-main-widget" 
                                                    data-product-id={product.product_id} 
                                                    data-name={product.title} 
                                                    data-url={`https://refer.prestigelabs.com/product/${product.slug}`} 
                                                    data-image-url={product.main_image} 
                                                    data-description={product.short_description} 
                                                    data-product-sku={this.state.productSKU}> 
                                                </div>

                                                <span class="stamped-product-reviews-badge stamped-main-badge" data-id={product.product_id}></span>
                                                {/* Stamped Reviews  */}
                                            </Col>
                                        </Row> :
                                        <div class="d-flex justify-content-center inactive_product">
                                            <h1 className="font_41 m-0 text_black fw_el_semi let_spa_2">Oops...! This product is not active...!</h1>
                                        </div>
                                    }
                                </div>
                            </div>
                            {/* Custom bundle product modal start  */}
                            <div className="modal fade" id={'alertModel'} tabIndex="-1" role="dialog" aria-labelledby="alertModel" aria-hidden="true">
                                <div className="modal-dialog" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header cus-modal-header">
                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body montserrat">
                                            <p className="font_16 text_black fw_ar_reg m-0">
                                                You cannot add a regular product to your cart while a custom bundle product is already in it, You can delete custom bundle product to proceed with the regular product.
                                            </p>
                                        </div>
                                        <div className="modal-footer">
                                        <button type="button" onClick={(e) => this.handleCart()} className="cus_button" data-dismiss="modal">OK</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Custom bundle product modal  */}
                        </Fragment>
                }
            </Fragment>
        );
    }
}

const mapDispachToProps = (dispach) => {
    return {
        addToCart: () => dispach({ type: 'ADD_TO_CART', value: JSON.parse(GET_STORAGE('cart')) })
    }
}

export default connect(null, mapDispachToProps)(ProductDrtails);
