import { AJAX_REQUEST, AJAX_PUBLIC_REQUEST, IS_AUTHENTICATED } from '../../Constants/AppConstants';

export function checkoutRequest(cartData) {

    return dispatch => {
        let request_result;
        if(IS_AUTHENTICATED()){
            request_result = AJAX_REQUEST("POST", "order/create", cartData);
        }
        else{
            request_result = AJAX_PUBLIC_REQUEST("POST", "order/create", cartData);
        }

        request_result.then(results => {
            
            if (results.response.code === 1000 ||results.response.code === 200) {
                return results.response.data;
            }
            
        });
        return request_result;
    }
}