import React, { Fragment, PureComponent } from "react";
import Parser from "html-react-parser";
import { NavLink } from "react-router-dom";
import { AJAX_PUBLIC_REQUEST } from "../../Constants/AppConstants";
import SingleProductListGrid from "./SingleProductListGrid";
import SingleProductModal from "./SingleProductModal";
import Pagination from "../Common/Pagination";
import SingleProductNotAvailable from './SingleProductNotAvailable'
import SingleProductNotAvailableModal from './SingleProductNotAvailableModal';
import HomeIcon from './../../Assets/images/refer_images/home_icon.svg'
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import FilterIcon from './../../Assets/images/refer_images/filter_icon.svg'
import SearchIcon from './../../Assets/images/customer_images/header_search.svg'

class Products extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            products: [],
            product_filter: [],
            productDetails: {},
            filter_by: "popular",
            listView: false,
            ProductLink: '',
            // productNotAvailable:["Bulletproof Vitality For Her","Women's Ultimate","Women's Immune Booster"],
            productNotAvailable: [],
            // Pagination Config
            item_count: 0,
            total_records: 0,
            total_page: 0,
            per_page: 0,
            pagenum: 1,
            products_filtered: [],
            products_filters: [],
            searchName: '',
        };
        document.title = "Products -Prestige Labs";
    }

    componentDidMount() {
        document.querySelector("body").scrollIntoView();
        this.getProducts(this.state.filter_by, this.state.pagenum);
        this.getProduct_filter(this.state.filter_by, this.state.pagenum);
    }

    pagenationHandle = pageNumber => {
        this.setState({ loading: true });
        this.getProducts(this.state.filter_by, pageNumber);
    };

    gridListView = e => {
        this.setState({ listView: !this.state.listView });
    };

    filterBy = e => {
        let filter_by = e.target.value;
        this.setState({
            loading: true,
            [e.target.name]: filter_by
        });
        this.getProducts(filter_by, 1);
    };

    getProducts = (filterBy, pageNumber) => {
        const data = {
            filter_by: filterBy,
            pagenum: parseInt(pageNumber)
        };
        AJAX_PUBLIC_REQUEST("POST", "product/getList", data).then(results => {
            if (results.response.code === 1000) {
                this.setState({
                    products: results.response.data,
                    products_filtered: results.response.data,
                    loading: false,
                    // Pagination Config
                    item_count: parseInt(results.response.data.length),
                    total_records: parseInt(results.response.total_records),
                    total_page: parseInt(results.response.total_page),
                    per_page: parseInt(results.response.per_page),
                    pagenum: parseInt(results.response.pagenum)
                });
            } else {
                this.setState({
                    error: Parser("<p className='text-danger'>" + results.response.message + "</p>"),
                    loading: false,
                    // Pagination Config
                    item_count: 0,
                    total_records: 0,
                    total_page: 0,
                    per_page: 0,
                    pagenum: 1
                });
            }
        });
    };

    getProduct_filter = (filterBy, pageNumber) => {
        const data = {
            filter_by: filterBy,
            // pagenum: parseInt(pageNumber)
        };
        AJAX_PUBLIC_REQUEST("POST", "product/getList", data).then(results => {
            if (results.response.code === 1000) {
                this.setState({
                    product_filter: results.response.data,
                    products_filters: results.response.data,
                    loading: false,
                    
                });
            } else {
                this.setState({
                    error: Parser("<p className='text-danger'>" + results.response.message + "</p>"),
                    loading: false,
                    
                });
            }
        });
    };

    quickView = productId => {
        this.setState({ productDetails: {} });
        const data = {
            product_id: productId
        };
        AJAX_PUBLIC_REQUEST("POST", "product/getDetails", data).then(results => {
            if (parseInt(results.response.code) === 1000) {
                this.setState({
                    productDetails: results.response.data
                });
            } else {
                this.setState({
                    error: Parser("<p className='text-danger'>" + results.response.message + "</p>")
                });
            }
        });
    };

    filterProduct = (e) => {
        this.setState({
            searchName: e.target.value,
            products_filtered: this.state.product_filter.filter(function (product) {
                if (e.target.value === '') {
                    return product;
                } else {
                    let string = product.title.toUpperCase();
                    let substring = e.target.value.toUpperCase();
                    if (string.includes(substring)) {
                        return product;
                    }
                }
            }.bind(this))
        });
    }

    notAvailablePopup = (link) => {
        this.setState({
            ProductLink: link,
        });
    }


    render() {
        return (
            <Fragment>
                {this.state.loading ? (
                    <div className="loading container full_page_loader" />
                ) : (
                    <Fragment>
                        <div className="suplements_blk">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <main className="athletes_list_wrapper">
                                            <div className="d-flex align-items-center mb_28 justify-content-between">
                                                <div className='pro_head_blk'>
                                                    <h2 className="product_itle text_black let_spa_2 font_24 fw_el_semi mb_6">Shop</h2>
                                                    <nav aria-label="breadcrumb" className="m-0">
                                                        <ol className="breadcrumb cus_breadcrumb pro_breadcrumb">
                                                            <li className="breadcrumb-item"><NavLink to="/"><img src={HomeIcon} alt="home_icon" /></NavLink></li>
                                                            <li className="breadcrumb-item active" aria-current="page"> ~ Shop</li>
                                                        </ol>
                                                    </nav>
                                                </div>
                                                <div className="input_bundle_btn d-flex align-items-center justify-content-end">
                                                    <form className="product-search-form product_search_input nxt_bundle_btn position-relative form_input_blk" method="get">
                                                        <div className="search-input-group">
                                                            <input onChange={this.filterProduct} value={this.state.searchName}
                                                                className="form-control" placeholder="Search" name="searchName" id="filter-by" type="text" />
                                                        </div>
                                                        <div className="search_icon position-absolute d-flex align-items-center justify-content-center">
                                                            <img src={SearchIcon} alt="search icon" />
                                                        </div>
                                                    </form>
                                                    <NavLink className="build_bundle pro_build_btn add_cart_btn font_16 fw-reg d-block text-center" to={"/create-custom-bundle"} >Build Your Own Bundle</NavLink>
                                                </div>
                                            </div>



                                            {/* <div className="view-controls-wrapper">
                                                <p className="pull-left">
                                                    Showing &nbsp;
                                                    {this.state.pagenum <= 1
                                                        ? this.state.pagenum + "-" + this.state.item_count
                                                        : (this.state.pagenum - 1) * this.state.per_page +
                                                        Number(1) +
                                                        "-" +
                                                        ((this.state.pagenum - 1) * this.state.per_page +
                                                            Number(this.state.item_count))}
                                                    &nbsp; of {this.state.total_records} results
                                                </p>
                                                <div className="pull-left list-grid-switcher">
                                                    <span
                                                        onClick={this.gridListView}
                                                        className={!this.state.listView ? "active" : ""}
                                                        title="Grid View"
                                                    >
                                                        <i className="fa fa-th" aria-hidden="true" />
                                                    </span>
                                                    <span
                                                        onClick={this.gridListView}
                                                        className={this.state.listView ? "list active" : "list"}
                                                        title="List View"
                                                    >
                                                        <i className="fa fa-th-list" aria-hidden="true" />
                                                    </span>
                                                </div>

                                                <div className="pull-right paginator">
                                                    <span className="shop-label">Filter By</span>
                                                    <form className="product-pager" method="get">
                                                        <select
                                                            onChange={this.filterBy}
                                                            value={this.state.filter_by}
                                                            name="filter_by"
                                                            className="roboto_condensed orderby"
                                                        >
                                                            <option value="popular">Sort by popularity</option>
                                                            <option value="latest">Sort by latest</option>
                                                            <option value="price_low">
                                                                Sort by price: low to high
                                                            </option>
                                                            <option value="price_heigh">
                                                                Sort by price: high to low
                                                            </option>
                                                        </select>
                                                    </form>
                                                </div>
                                                <div className="clearfix" />
                                            </div> */}

                                            <div className="row sup_pro_row">
                                                {this.state.products_filtered.length <= 0
                                                    ? <h3>No Product Matched!</h3>
                                                    : this.state.products_filtered.map(
                                                        function (product, key) {
                                                            product.notAvailablePopup = this.state.productNotAvailable.includes(product.title) ? this.notAvailablePopup : "";
                                                            // Added product quickviewer
                                                            product.quickView = this.quickView;
                                                            return this.state.topSellerShow <= key ? (
                                                                ""
                                                            ) :
                                                                (this.state.productNotAvailable.includes(product.title)) ?
                                                                    <div key={Math.random()} className={this.state.listView ? 'col-xs-12 col-sm-4 col-md-4 col-lg-3 list_view' : 'col-xs-12 col-sm-4 col-md-4 col-lg-3'}>
                                                                        <SingleProductNotAvailable product={product} type='topSeller' />
                                                                    </div> :
                                                                    (
                                                                        <div
                                                                            key={Math.random()}
                                                                            className={
                                                                                this.state.listView
                                                                                    ? "col-md-3 list_view"
                                                                                    : "col-md-3"
                                                                            }
                                                                        >
                                                                            <SingleProductListGrid
                                                                                key={key}
                                                                                product={product}
                                                                            />
                                                                        </div>
                                                                    );
                                                        }.bind(this)
                                                    )}
                                            </div>
                                            {this.state.searchName == ''?
                                            <Pagination
                                                pagenationHandle={this.pagenationHandle}
                                                total_records={this.state.total_records}
                                                total_page={this.state.total_page}
                                                per_page={this.state.per_page}
                                                pagenum={this.state.pagenum}
                                            />
                                            :""
                                            }
                                        </main>
                                    </div>
                                </div>
                                <SingleProductModal product={this.state.productDetails} />
                                <SingleProductNotAvailableModal productLink={this.state.ProductLink} />
                            </div>
                        </div>

                        {/* filter section HTML */}
                        {/* <div className="pro_filter_sec d-flex align-items-center justify-content-center">
                            <div className="filter_pro_col d-flex align-items-center">
                                <img src={FilterIcon} alt="filter_icon" />
                                <span className="d-block font_16 text_white pl_7 fw_ar_reg">Filters</span>
                            </div>
                            <div className="filter_select">
                                <DropdownButton id="category_dropdown" title="Categories">
                                    <Dropdown.Item>category</Dropdown.Item>
                                </DropdownButton>
                            </div>
                            <div className="filter_select">
                                <DropdownButton id="priceDropdown" title="Price">
                                    <Dropdown.Item>Price</Dropdown.Item>
                                </DropdownButton>
                            </div>
                            <div className="filter_select">
                                <DropdownButton id="ratingDropdown" title="Ratings">
                                    <Dropdown.Item>5 Rating</Dropdown.Item>
                                    <Dropdown.Item>4 Rating</Dropdown.Item>
                                    <Dropdown.Item>3 Rating</Dropdown.Item>
                                    <Dropdown.Item>2 Rating</Dropdown.Item>
                                    <Dropdown.Item>1 Rating</Dropdown.Item>
                                </DropdownButton>
                            </div>
                        </div> */}
                        {/* filter section HTML - Ends */}
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default Products;
