import { AJAX_PUBLIC_REQUEST, AJAX_SERVICE_LOGIN_REQUEST, SET_STORAGE, USER, GET_STORAGE, SET_LOGIN_COOKIE } from '../../Constants/AppConstants';
import { SET_CURRENT_USER } from './actionTypes';

export function setCurrentUser(user) {
    return {
        type: SET_CURRENT_USER,
        user
    }
}

export function userSignupRequest(userData) {
    return dispatch => {
        const request_result = AJAX_PUBLIC_REQUEST("POST", "customer/registration", userData);
        request_result.then(results => {
            if (results.response.code === 1000) {
                const user_data = results.response.data;
                user_data.remember = false;
                if (userData.remember === 'checked') {
                    user_data.remember = true;
                }

                SET_STORAGE(USER, JSON.stringify(user_data));
                SET_LOGIN_COOKIE(JSON.stringify(user_data));
                SET_STORAGE('meal_menu_access', 'false');
                SET_STORAGE('meal_menu_access_code', '');
                const cur_storage2 = GET_STORAGE(USER);
                const cur_storage = JSON.parse(cur_storage2);
                dispatch(setCurrentUser(cur_storage));
            }
        });
        return request_result;
    }
}

export function serviceLoginRequest(userData) {
    return dispatch => {
        const request_result = AJAX_SERVICE_LOGIN_REQUEST("POST", "user/details", userData);
        request_result.then(results => {
            if (results.response.code === 1000) {
                const user_data = results.response.data;
                user_data.remember = false;
                if (userData.remember === 'checked') {
                    user_data.remember = true;
                }
                SET_STORAGE(USER, JSON.stringify(user_data));
                SET_LOGIN_COOKIE(JSON.stringify(user_data));
                SET_STORAGE('meal_menu_access', 'false');
                SET_STORAGE('meal_menu_access_code', '');
                const cur_storage2 = GET_STORAGE(USER);
                const cur_storage = JSON.parse(cur_storage2);
                dispatch(setCurrentUser(cur_storage));
            }
        });
        return request_result;
    }
}