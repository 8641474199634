import React, { PureComponent } from 'react';
import { NavLink } from 'react-router-dom';
import classnames from 'classnames';
import Parser from 'html-react-parser';
import { AJAX_PUBLIC_REQUEST, IS_DEMO_SITE, DEMO_SITE_WARNING_TEXT, IS_AUTHENTICATED } from '../../Constants/AppConstants';
import moment from 'moment';
import { Row, Col } from 'react-bootstrap';
import SendMailIcon from './../../Assets/images/refer_images/send_icon.svg'
import PrestigeLogo from './../../Assets/images/public_images/footer_logo.svg'
import LocationIcon from './../../Assets/images/refer_images/location_icon.svg'
import EmailIcon from './../../Assets/images/refer_images/email_icon.svg'
import PhoneIcon from './../../Assets/images/refer_images/phone_icon.svg'
import InstagramIcon from './../../Assets/images/refer_images/instagram_icon.svg'
import FacebookIcon from './../../Assets/images/refer_images/facebook_icon.svg'

class Footer extends PureComponent {
    state = {
        page: '',
        footer_menu: '',
        is_demo_active: true
    }

    componentDidMount() {
        AJAX_PUBLIC_REQUEST("POST", "menu/getFooterInfo", {}).then(results => {
            if (parseInt(results.response.code) === 1000) {
                this.setState({
                    footer_menu: results.response.data,
                });
            }
        });
        AJAX_PUBLIC_REQUEST("POST", "page/getContactInfo", {}).then(results => {
            if (parseInt(results.response.code) === 1000) {
                this.setState({
                    page: results.response.data,
                });
            }
        });
    }

    scrollToTop = (e) => {
        e.preventDefault();
        document.querySelector("body").scrollIntoView();
    }

    onCliclActive = (e) => {
        const elements = document.querySelectorAll('.menu-main-menu-container ul li');
        [].forEach.call(elements, function (el) {
            el.classList.remove("active");
        });
        e.currentTarget.classList.add('active');
        document.getElementById("scrollTop").scrollIntoView();
    }

    DemoSiteWarningActive = () => {
        this.setState({
            is_demo_active: true
        })
    }

    DemoSiteWarningInactive = () => {
        this.setState({
            is_demo_active: false
        })
    }

    render() {
        return (
            <React.Fragment>
                <footer className="site-footer refer_footer">
                    <div className="container-fluid">
                        <a href="#" className="footer_logo mb_16 d-block">
                            <img src={PrestigeLogo} alt="PrestigeLogo" />
                        </a>
                        <Row>
                            <Col md={2} className='learn_menu_blk'>
                                <h4 className="font_16 barlow-semi-condensed-bold text_white mb_12">LEARN</h4>
                                <ul className="footer_menu hide_get_touch">
                                    <li>
                                        <a href="/page/about-us" className="text_white barlow-semi-condensed-medium font_16">About Us</a>
                                    </li>
                                    <li>
                                        <a href="/page/faq-info" className="text_white barlow-semi-condensed-medium font_16">FAQs</a>
                                    </li>
                                    {/* <li>
                                        <a href="#" className="text_white barlow-semi-condensed-medium font_16">Reviews</a>
                                    </li> */}
                                    {
                                        this.state.footer_menu.col3 ?
                                            this.state.footer_menu.col3.content.map(function (content_menu2, key) {
                                                if (content_menu2.type === "external") {
                                                    return (<li key={Math.random()}><a className="text_white barlow-semi-condensed-medium font_16" target={(content_menu2.open_new_tab == 'yes') ? "_blank" : ""} href={content_menu2.url}>{content_menu2.label}</a></li>)
                                                } else {
                                                    return (<li key={Math.random()}><NavLink className="text_white barlow-semi-condensed-medium font_16" to={content_menu2.url}>{content_menu2.label}</NavLink></li>)
                                                }
                                            })
                                            : null
                                    }
                                </ul>
                            </Col>
                            <Col md={2} className='learn_menu_blk'>
                                <h4 className="font_16 barlow-semi-condensed-bold text_white mb_12">ACCOUNT</h4>
                                <ul className="footer_menu">
                                    <li>
                                        <a href="/login" className="text_white barlow-semi-condensed-medium font_16">Account Login</a>
                                    </li>
                                </ul>
                            </Col>
                            <Col md={2} className='learn_menu_blk'>
                                <h4 className="font_16 barlow-semi-condensed-bold text_white mb_12">CONNECT</h4>
                                <ul className="footer_menu">
                                    <li>
                                        <a href="https://www.instagram.com/prestigelabs/?hl=en" target="_blank" className="text_white barlow-semi-condensed-medium font_16">Instagram</a>
                                    </li>
                                    <li>
                                        <a href="https://www.facebook.com/Prestige-Labs/" target="_blank" className="text_white barlow-semi-condensed-medium font_16">Facebook</a>
                                    </li>
                                </ul>
                            </Col>
                            <Col md={2} className='contact_footer_blk'>
                                <h4 className="font_16 barlow-semi-condensed-bold text_white mb_12">CONTACT</h4>
                                <ul className="footer_menu">
                                <a href="/contact"><li className="text_white barlow-semi-condensed-medium font_16">Contact Form</li></a>
                                    <li className="text_white barlow-semi-condensed-medium font_16">
                                        <span className="d-block text_white">1-800-470-7560</span>
                                        <span className="d-block text_white">support@prestigelabs.com</span>
                                        <span className="d-block text_white">GLS Labs LLC</span>
                                        <span className="d-block text_white">9550 South Eastern Avenue Suite 253</span>
                                        <span className="d-block text_white">Las Vegas NV 89123</span>
                                    </li>
                                </ul>
                                {/* <ul className="d-flex social_media_link">
                                    <li>
                                        <a href="/contact" className="text_white barlow-semi-condensed-medium font_16">GET  IN TOUCH</a>
                                    </li>
                                </ul> */}
                            </Col>
                            <Col md={4}>
                                <div>
                                    <h4 className="font_20 white_text barlow-semi-condensed-bold mb_12">Join the Prestige Labs community</h4>
                                    <p className="font_16 white_text barlow-semi-condensed-medium">Be first in line for new arrivals, sales and <br />other exclusive offers</p>
                                    <div class="klaviyo-form-Wqv8Jg join_newsletter_blk m-0"></div>
                                </div>
                                {/* <form action="" className="w-100 position-relative mb_41">
                                    <input type="text" className="sent_mail_input w-100 fw_ar_reg font_16 text_black" placeholder='Email ID' />
                                    <a href="#" className="send_mail_link d-flex">
                                        <img src={SendMailIcon} alt="send_mail_icon" />
                                    </a>
                                </form> */}
                                {/* <a href="#" className="footer_logo mb_16 d-block">
                                    <img src={PrestigeLogo} alt="PrestigeLogo" />
                                </a> */}
                                {/* <div className="add_sec">
                                    <div className="d-flex location_blk align-items-start">
                                        <img src={LocationIcon} alt="Location_icon" className='location_icon' />
                                        <span className="d-block font_16 text_white fw_ar_reg pl_10">9550 South Eastern Avenue Suite 253 Las Vegas, NV 89123</span>
                                    </div>
                                    <div className="d-flex location_blk align-items-center">
                                        <img src={EmailIcon} alt="email_icon" />
                                        <a href="#" className="d-block font_16 text_white fw_ar_reg pl_10 text_underline">support@prestigelabs.com</a>
                                    </div>
                                    <div className="d-flex location_blk align-items-center">
                                        <img src={PhoneIcon} alt="phone icon" />
                                        <span className="d-block font_16 text_white fw_ar_reg pl_10">1-800-470-7560</span>
                                    </div>
                                </div> */}
                            </Col>
                            <Col md={2} className='empty'>
                            </Col>
                            <Col md={10} className=''>
                                    <p className='font_10 barlow-semi-condensed-medium text_white m-0'>*These Statements Have Not Been Evaluated By The Food And Drug Administration. The Products And Information On This Website Are Not Intended To Diagnose, Treat, Cure Or Prevent Any Disease. The Information On This Site Is For Educational Purposes Only And Should Not Be Considered Medical Advice. Please Prestige Labs Is For Adults Aged 18 And Older. If You Are Pregnant Or Undergoing Treatment For A Medical Condition, Please Consult Your Physician Before Taking Prestige Labs.</p>
                            </Col>
                        </Row>
                    </div>
                    {/* <aside className="footer-widgets col-xs-12">
                        <div className="container">
                            <div className="row footer-top">
                                <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                                    <section className="widget widget_text">
                                        <h3 className="montserrat">{this.state.footer_menu.col1 ? this.state.footer_menu.col1.title : ''}</h3>
                                        <div className="textwidget">
                                            {this.state.footer_menu.col1 ? Parser(this.state.footer_menu.col1.content) : ''}
                                        </div>
                                    </section>
                                </div>
                                <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                                    <section className="widget widget_nav_menu">
                                        <h3 className="montserrat">{this.state.footer_menu.col2 ? this.state.footer_menu.col2.title : ''}</h3>
                                        <div className="menu-footer-menu-c2-container">
                                            <ul className="menu">
                                                {
                                                    this.state.footer_menu.col2 ?
                                                        this.state.footer_menu.col2.content.map(function (content_menu, key) {
                                                            if (content_menu.type === "external") {
                                                                return (<li className="menu-item menu-item-type-post_type menu-item-object-page" key={Math.random()}><a target={(content_menu.open_new_tab === 'yes') ? "_blank" : ""} href={content_menu.url}>{content_menu.label}</a></li>)
                                                            } else {
                                                                return (<li className="menu-item menu-item-type-post_type menu-item-object-page" key={Math.random()}><NavLink to={content_menu.url}>{content_menu.label}</NavLink></li>)
                                                            }
                                                        })
                                                        : null
                                                }
                                            </ul>
                                        </div>
                                    </section>
                                </div>
                                <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3  ">
                                    <section className="widget widget_nav_menu">
                                        <h3 className="montserrat">{this.state.footer_menu.col3 ? this.state.footer_menu.col3.title : ''}</h3>
                                        <div className="menu-footer-menu-c3-container">
                                            <ul className="menu">
                                                {
                                                    this.state.footer_menu.col3 ?
                                                        this.state.footer_menu.col3.content.map(function (content_menu2, key) {
                                                            if (content_menu2.type === "external") {
                                                                return (<li key={Math.random()}><a target={(content_menu2.open_new_tab === 'yes') ? "_blank" : ""} href={content_menu2.url}>{content_menu2.label}</a></li>)
                                                            } else {
                                                                return (<li key={Math.random()}><NavLink to={content_menu2.url}>{content_menu2.label}</NavLink></li>)
                                                            }
                                                        })
                                                        : null
                                                }
                                            </ul>
                                        </div>
                                    </section>
                                </div>
                                <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                                    <section id="text-4" className="widget widget_text">
                                        <h3 className="">{this.state.footer_menu.col4 ? this.state.footer_menu.col4.title : ''}</h3>
                                        <div className="textwidget">
                                            {this.state.footer_menu.col4 ? Parser(this.state.footer_menu.col4.content) : ''}
                                        </div>
                                    </section>
                                </div>
                            </div>
                            <div className="row footer-top">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <div className="footer-bottom">
                                        <div className="copyright">Copyright &copy; {moment().format('Y')} Prestige Labs,  All Rights Reserved.</div>
                                        <div id="payment-methods">
                                            <span className="payment-method">
                                                <a href="#" title="Visa">
                                                    <img src={require("../../Assets/images/icon-cc-visa.png")} alt="visa" />
                                                </a>
                                            </span>
                                            <span className="payment-method">
                                                <a href="#" title="American express">
                                                    <img src={require("../../Assets/images/icon-cc-american-express.png")} alt="american-express" />
                                                </a>
                                            </span>
                                            <span className="payment-method">
                                                <a href="#" title="Discover">
                                                    <img src={require("../../Assets/images/icon-cc-discover.png")} alt="discover" />
                                                </a>
                                            </span>
                                            <span className="payment-method">
                                                <a href="#" title="Mastercard">
                                                    <img src={require("../../Assets/images/icon-cc-mastercard.png")} alt="mastercard" />
                                                </a>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </aside> */}
                </footer>
                {/* <a href="#" onClick={this.scrollToTop} className="scrollup"><i className="fa fa-angle-up" aria-hidden="true"></i></a>
                {
                    IS_DEMO_SITE ?
                        <div className={classnames('siteNoteWrapper', { 'active': !this.state.is_demo_active })}>
                            <span className="siteNoteWrapperShow" onClick={this.DemoSiteWarningActive}><i className="fa fa-info" aria-hidden="true"></i></span>
                            <div className="montserrat siteNote" >
                                <span>{DEMO_SITE_WARNING_TEXT}</span>
                                <span className="siteNoteClose" onClick={this.DemoSiteWarningInactive}>Dismiss</span>
                            </div>
                            <div className="clearfix"></div>
                        </div>
                        : ''
                } */}
            </React.Fragment>
        );
    }
}

export default Footer;
