import React, { PureComponent, Fragment } from 'react';
import { AJAX_PUBLIC_REQUEST,GET_STORAGE ,AJAX_REQUEST,DESTROY_CART, BASE_URL} from '../../Constants/AppConstants';
import "../../Assets/css/captcha.css";

class paypal_payment extends PureComponent {

    constructor(props) {
        super(props)
        this.state = {
            page: '',
            name: '',
            email: '',
            subject: '',
            message: '',
            success_alert_wrapper_show: false,
            loading: true,
            action_btn_label: false,
            errors: {},
            isValid: false,
            isLoading: false,
            isFormValid: true,
            server_message: '',
        }
        document.title = "Payment - Prestige Labs";
    }

    componentDidMount() {
        const paypal_order_id = GET_STORAGE('paypal_order_id')
        const order_id = GET_STORAGE('order_id')
        if (paypal_order_id !== 'undefined' && paypal_order_id !== null && paypal_order_id !== undefined) {
            let data = {
                paypal_order_id: paypal_order_id,
                order_id : order_id
            }
            AJAX_REQUEST("POST", "order/payment_authorization", data ).then(results => {
                if (results.response) {
                    const redirectURL = BASE_URL + 'order-received/' + order_id;
                    console.log("New URL ==", redirectURL);
                    DESTROY_CART();
                    window.location.href = redirectURL;
                } else {
                    this.setState({
                        server_message: results.response.message,   
                        isFormValid: false,
                        loading: false,
                    });
                }
            })
            .catch(error => {
                console.log(error);
                // Handle the error here, e.g., show an error message to the user.
            });
            document.querySelector("body").scrollIntoView();
        } else {
            const redirectURL =  BASE_URL + `order-received/${order_id}`;
            DESTROY_CART();
            window.location.href = redirectURL;
        }
    }

    render() {
        const { server_message, success_alert_wrapper_show, errors, isLoading } = this.state;
        const errors_data = server_message;
        return <div className='loading container full_page_loader'></div>;
    }
}

export default paypal_payment;
