import { SET_STORAGE, USER, GET_STORAGE, REMOVE_STORAGE, AJAX_PUBLIC_REQUEST, SET_LOGIN_COOKIE, DELETE_LOGIN_COOKIE } from '../../Constants/AppConstants';
import history from '../../history';
import { SET_CURRENT_USER } from './actionTypes';

export function setCurrentUser(user) {
    return {
        type: SET_CURRENT_USER,
        user
    }
}

export function logout() {
    return dispatch => {
        REMOVE_STORAGE(USER);
        REMOVE_STORAGE('meal_menu_access');
        REMOVE_STORAGE('meal_menu_access_code');
        DELETE_LOGIN_COOKIE();
        window.FrontChat('shutdown', { clearSession: true });
        dispatch(setCurrentUser({}));
        history.push('/login');
        window.location.reload();
    }
}

export function serviceLogout() {
    return dispatch => {
        REMOVE_STORAGE(USER);
        REMOVE_STORAGE('meal_menu_access');
        REMOVE_STORAGE('meal_menu_access_code');
        DELETE_LOGIN_COOKIE();
        dispatch(setCurrentUser({}));
    }
}

export function userLoginRequest(userData) {
    return dispatch => {
        const request_result = AJAX_PUBLIC_REQUEST("POST", "user/login", userData);
        request_result.then(results => {
            if (results.response.code === 1000) {
                const user_data = results.response.data;
                user_data.remember = false;
                if (userData.remember === 'checked') {
                    user_data.remember = true;
                }

                SET_STORAGE(USER, JSON.stringify(user_data));
                SET_LOGIN_COOKIE(JSON.stringify(user_data));
                SET_STORAGE('meal_menu_access', 'false');
                SET_STORAGE('meal_menu_access_code', '');
                const cur_storage2 = GET_STORAGE(USER);
                const cur_storage = JSON.parse(cur_storage2);
                dispatch(setCurrentUser(cur_storage));
            }
        }
        );
        return request_result;
    }
}

export function setUserLogin(userData) {
    return dispatch => {
        const user_data = userData;
        user_data.remember = false;

        SET_STORAGE(USER, JSON.stringify(user_data));
        SET_LOGIN_COOKIE(JSON.stringify(user_data));
        SET_STORAGE('meal_menu_access', 'false');
        SET_STORAGE('meal_menu_access_code', '');
        const cur_storage2 = GET_STORAGE(USER);
        const cur_storage = JSON.parse(cur_storage2);
        dispatch(setCurrentUser(cur_storage));

        return true;
    }
}